import { categoryADTypeEnum } from '@/constant/ad/category-ad-type';
import { brandLevel2ADTypeEnum } from '@/constant/ad/brand-level-2-ad-type';
import { brandLevel3ADTypeEnum } from '@/constant/ad/brand-level-3-ad-type';
import { checkVirtualShelf } from '@/helper/virtual-shelf';
import { getSwiperGlobalOptions, modifySwiperOptionForVisualTest } from '@/helper/swiper';

// category Level1 要顯示的版位
export const level1Positions = [
  categoryADTypeEnum.hotTopic,
  categoryADTypeEnum.keywords,
  categoryADTypeEnum.emphasize,
  categoryADTypeEnum.bigBanner,
  categoryADTypeEnum.focusEvent,
  categoryADTypeEnum.newProducts,
  // categoryADTypeEnum.hotTopicChart,
  categoryADTypeEnum.dailyRecommend,
  categoryADTypeEnum.editorRecommend,
  categoryADTypeEnum.mainTopicProject,
  categoryADTypeEnum.categoryRecommend,
  categoryADTypeEnum.mainPromptProducts,
  categoryADTypeEnum.hotTopicSmallBanner,
  categoryADTypeEnum.exhibitionSmallBanner,
];

// category Level1 虛擬貨架要顯示的版位
export const virtualShelfLevel1Positions = [
  // categoryADTypeEnum.hotTopic,
  // categoryADTypeEnum.keywords,
  // categoryADTypeEnum.emphasize,
  categoryADTypeEnum.bigBanner,
  categoryADTypeEnum.focusEvent,
  categoryADTypeEnum.newProducts,
  // categoryADTypeEnum.hotTopicChart,
  categoryADTypeEnum.dailyRecommend,
  categoryADTypeEnum.editorRecommend,
  // categoryADTypeEnum.mainTopicProject,
  categoryADTypeEnum.categoryRecommend,
  categoryADTypeEnum.mainPromptProducts,
  // categoryADTypeEnum.hotTopicSmallBanner,
  // categoryADTypeEnum.exhibitionSmallBanner,
];

// category Level2 要顯示的版位
export const level2Positions = [
  categoryADTypeEnum.hotTopic,
  categoryADTypeEnum.keywords,
  categoryADTypeEnum.emphasize,
  categoryADTypeEnum.bigBanner,
  categoryADTypeEnum.focusEvent,
  categoryADTypeEnum.newProducts,
  // categoryADTypeEnum.hotTopicChart,
  categoryADTypeEnum.dailyRecommend,
  categoryADTypeEnum.editorRecommend,
  categoryADTypeEnum.mainTopicProject,
  categoryADTypeEnum.categoryRecommend,
  categoryADTypeEnum.mainPromptProducts,
  categoryADTypeEnum.hotTopicSmallBanner,
  categoryADTypeEnum.exhibitionSmallBanner,
];

// category Level3 要顯示的版位
export const level3Positions = [categoryADTypeEnum.horizontalPaint, categoryADTypeEnum.mainPromptProducts];

export const categoryPosition = {
  1: level1Positions,
  2: level2Positions,
  3: level3Positions,
};

const virtualShelfCategoryPosition = {
  1: virtualShelfLevel1Positions,
};

export const getCategoryPosition = (level) => {
  if (!checkVirtualShelf()) return categoryPosition[level];
  return virtualShelfCategoryPosition[level] || categoryPosition[level];
};

export const brandLevel2ADPosition = [
  brandLevel2ADTypeEnum.bigBanner,
  brandLevel2ADTypeEnum.brandIntro,
  brandLevel2ADTypeEnum.brandEvent1,
  brandLevel2ADTypeEnum.brandEvent2,
  brandLevel2ADTypeEnum.brandEvent3,
  brandLevel2ADTypeEnum.brandEvent4,
  brandLevel2ADTypeEnum.brandEvent5,
  brandLevel2ADTypeEnum.featureKind,
  brandLevel2ADTypeEnum.straightBanner,
  brandLevel2ADTypeEnum.recommendProducts,
  brandLevel2ADTypeEnum.horizontalPaint,
  brandLevel2ADTypeEnum.emphasizeRecommend,
  brandLevel2ADTypeEnum.brandList,
];

const virtualShelfBrandLevel2ADPosition = [
  brandLevel2ADTypeEnum.bigBanner,
  brandLevel2ADTypeEnum.brandIntro,
  brandLevel2ADTypeEnum.brandEvent1,
  brandLevel2ADTypeEnum.brandEvent2,
  brandLevel2ADTypeEnum.brandEvent3,
  brandLevel2ADTypeEnum.brandEvent4,
  brandLevel2ADTypeEnum.brandEvent5,
  // brandLevel2ADTypeEnum.featureKind,
  brandLevel2ADTypeEnum.straightBanner,
  // brandLevel2ADTypeEnum.recommendProducts,
  // brandLevel2ADTypeEnum.horizontalPaint,
  // brandLevel2ADTypeEnum.emphasizeRecommend,
  // brandLevel2ADTypeEnum.brandList,
];

export const getBrandLevel2ADPosition = () => {
  if (checkVirtualShelf()) return virtualShelfBrandLevel2ADPosition;
  return brandLevel2ADPosition;
}

export const brandLevel3ADPosition = [brandLevel3ADTypeEnum.bigBanner];

/**
 swiper setting
 */
export const bigBannerSwiperOption = modifySwiperOptionForVisualTest({
  ...getSwiperGlobalOptions(),
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  mousewheel: false,
  keyboard: true,
  speed: 300,
  autoplay: {
    delay: 6000,
  },
});

export const tabStyle = {
  red: 'tabs-red',
  gold: 'tabs-golden',
  white: 'tabs-white',
};

<template>
  <div :class="boxClass">
    <img class="swiper-lazy" :class="imgClass" :src="pureImageSource" loading="lazy" :name="imgName" :alt="alt" @error="handleImageError" />
    <div class="swiper-lazy-preloader"></div>
  </div>
</template>

<script>
import { inject, toRefs, computed } from 'vue';
import WebpPathConvertService, { isOriginSourceWebp } from '@/services/webp-path-convert-service';
import { productDefaultx3Img } from '@/constant/images-path';

const props = {
  src: {
    type: String,
    default: '',
  },
  alt: {
    type: String,
    default: '',
  },
  // 更新 `swiper` duplicate item 用，單純傳進參數即可。
  refreshFlag: {
    type: Number,
    default: 0,
  },
  // common style : 公用 style 再改這，母湯歐北加
  isCircle: {
    type: Boolean,
    default: false,
  },
  isCover: {
    type: Boolean,
    default: false,
  },
  defaultImage: {
    type: String,
    default: productDefaultx3Img,
  },
  isDisplayBlock: {
    type: Boolean,
    default: false,
  },
};

const setup = (props) => {
  const { src, isCircle, isCover, alt, isDisplayBlock, defaultImage } = toRefs(props);
  const $isSupportWebp = inject('$isSupportWebp');

  const pureImageSource = computed(() => {
    if (isOriginSourceWebp) return src.value;
    return $isSupportWebp ? WebpPathConvertService.convertPath(src.value) : src.value;
  });
  const imgClass = computed(() => ({ circle: isCircle.value, cover: isCover.value }));
  const imgName = computed(() => {
    if (isOriginSourceWebp) return '';
    return `${alt.value}-webp-swiper`;
  });
  const boxClass = computed(() => {
    if (isDisplayBlock.value) return '';
    return 'd-inline';
  });

  const handleImageError = (event) => {
    event.target.src = defaultImage.value;
  };

  return {
    pureImageSource,
    imgClass,
    imgName,
    boxClass,
    handleImageError,
  };
};

export default {
  name: 'SwiperWebpImage',
  props,
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/utility/image/base-image.scss';
.swiper-lazy-preloader {
  background-size: contain;
  background-image: url('@/static/images/loading_large.gif');
  background-position: center;
  background-repeat: no-repeat;
  width: inherit;
  height: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  animation: none !important; // 覆蓋 swiperjs 樣式
  border: none;
}
</style>

import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { usePriceStore } from '@/stores/price';
import { usePriceQueueStore } from '@/stores/price-queue';
import { formatRuleTypeEnum } from '@/constant/product/product-price';
import { checkIsImageSnapshotTest } from '@/helper/check-image-snapshot-test';

export default function ({ guid, useQueue = true }) {
  const priceStore = usePriceStore();
  const priceQueueStore = usePriceQueueStore();
  const { getPrices, getPrePrices, getDiscountsOfTypes } = storeToRefs(priceStore);

  const isLoading = ref(true);

  /**
   * Computed
   */
  const prePrice = computed(() => getPrePrices.value[guid]);
  const price = computed(() => getPrices.value[guid]);
  const discountsOfTypes = computed(() => getDiscountsOfTypes.value[guid] || {});
  const discountWording = computed(() => discountsOfTypes.value[formatRuleTypeEnum.wording]);
  const discountPriceOnly = computed(() => discountsOfTypes.value[formatRuleTypeEnum.priceOnly]);

  /**
   * Methods
   */
  const targetFetchPrice = ({ productIds }) => {
    if (useQueue || checkIsImageSnapshotTest()) {
      return priceQueueStore.fetchPriceByQueue({ productIds });
    }
    return priceStore.fetchPrice({ productIds });
  };
  const setLoading = () => {
    isLoading.value = true;
  };
  const completeLoading = () => {
    isLoading.value = false;
  };

  return {
    isLoading,
    getPrices,
    getPrePrices,
    getDiscountsOfTypes,
    prePrice,
    price,
    discountsOfTypes,
    discountWording,
    discountPriceOnly,
    targetFetchPrice,
    setLoading,
    completeLoading,
  };
}

<template>
  <div class="container">
    <img v-lazy="page500Img" class="mx-auto my-4" alt="" src="" />
    <div class="title serif-font-bold mb-2 text-center">發生了一些小狀況，請稍候再試！</div>
    <div class="des text-center e-col-space">
      <div>湖水偶然間遇上詩人的一片雲，你偶然碰見了程式的一場謬誤</div>
      <div>所以，請跟著我們重新開始</div>
    </div>
    <div class="btn-item mt-3 text-center">
      <router-link to="/" class="btn btn-eslite-red mb-3">回首頁</router-link>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { page500Img } from '@/constant/images-path';
import { sentryLoggerLayout500 } from '@/helper/sentry-log';
import { useLayoutErrorStore } from '@/stores/layout-error';

const metaInfo = () => ({
  title: `500 程式錯誤 │ 誠品線上 - 閱讀與生活的無盡想像`,
  meta: [
    { property: 'og:title', content: `500 程式錯誤 │ 誠品線上 - 閱讀與生活的無盡想像`, vmid: 'og:title' },
    { property: 'og:image', content: 'https://www.eslite.com/eslite-og-logo.webp', vmid: 'og:image' },
  ],
});

export default {
  name: 'Error500',
  metaInfo,
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const route = useRoute();
    const layoutErrorStore = useLayoutErrorStore();
    onMounted(() => sentryLoggerLayout500(`[error-page][500] ${route.path}`, layoutErrorStore.getErrorMessage));

    return {
      page500Img,
    };
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 75%;
  display: block;
  @include mediaIs($grid-breakpoints-md) {
    width: 50%;
  }
}
.red {
  color: var(--eslite-red);
}
.content {
  text-align: center;
  padding: 0 20px;
}
.title {
  @include font24;
}
.des {
  @include font16;
}
.btn {
  width: 180px;
  border-radius: 4px;
  padding: 10px;
  color: var(--white);
  background-color: var(--eslite-red);
}
@include mediaMin($grid-breakpoints-lg) {
  img {
    width: 370px;
  }
  .content {
    text-align: center;
  }
  .title {
    @include font30;
  }
  .des {
    @include font19;
  }
}
</style>

import { defineStore } from 'pinia';

const state = () => ({
  isBodyLocked: false,
  savedScrollY: 0,
});

const actions = {
  lockBodyScroll() {
    if (this.isBodyLocked) return;
    const scrollY = window.pageYOffset;
    this.savedScrollY = scrollY;
    this.isBodyLocked = true;
    document.body.style.cssText = `position: fixed; top: -${scrollY}px; left: 0; right: 0; bottom: 0;`;
  },
  unlockBodyScroll() {
    if (!this.isBodyLocked) return;
    this.isBodyLocked = false;
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('top');
    document.body.style.removeProperty('left');
    document.body.style.removeProperty('right');
    document.body.style.removeProperty('bottom');
    window.scrollTo(0, this.savedScrollY);
  },
};

export const useLockBodyScrollStore = defineStore('lockBodyScroll', {
  state,
  actions,
});

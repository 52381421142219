import { computed } from 'vue';
import { useLoginStore } from '@/stores/login';

export function useProductImageRestrict({ disableLimit, isAdult }) {
  const loginStore = useLoginStore();
  // 成人商品限制（顯示遮罩）
  const restricted = computed(() => {
    // 關閉限制則回傳「不受限」
    if (disableLimit.value === true) return false;
    // 成人會員不受此限制
    const isAdultMember = loginStore.user?.is_adult || false;
    if (isAdultMember) return false;
    // 回傳商品預設限制
    return isAdult.value;
  });

  return {
    restricted,
  };
}

import { mergeAll, map, zipObj } from 'ramda';

/**
 * @const {array} homePageAD
 * 順序:
 * @face-1
 * focus_point [1. 注目焦點]
 * big_b_new [1. 大 banner]
 * home_page_recommend_of_the_day [1. 今日最推]
 * home_page_recommend_of_the_day_by_tabs [1. 今日最推v2]
 * hot_topic_image [1. 熱門話題]
 * hot_topic_text [1. 熱門話題]
 * hot_search [1. 流行熱搜]
 * member_little_b [1. 會員小 B]
 * @face-2
 * discount_big_b [2. 好康優惠大 B]
 * discount_little_b [2. 好康優惠小 B]
 * discount_area [2. 好康優惠專區]
 * home_page_horizontal_paint [2. 橫幅]
 * @face-3
 * home_page_online_leaderboard [3. g1 網路暢銷榜]
 * eslite_exclusive [3. g1 誠品獨家]
 * book_of_eslite_choice [3. g1 誠品選書]
 * music_of_eslite_choice [3. g1 誠品選樂]
 *
 * home_page_main_topic_project [3. g2 主題企劃]
 * brand_week [3. g2 品牌週]
 * @face-4
 * thread_group [4. 線別]
 */
export const homePageAD = [
  {
    description: '大Banner',
    code: 'big_b_new',
    key: 'bigBanner',
    mappingName: 'BigBanner',
  },
  {
    description: '注目焦點',
    code: 'focus_point',
    key: 'focusPoint',
    mappingName: 'FocusPoint',
  },
  {
    description: '今日最推',
    code: 'home_page_recommend_of_the_day_by_tabs',
    key: 'groupRecommend',
    mappingName: 'GroupRecommend',
  },
  {
    description: '熱門話題(文)',
    code: 'hot_topic_text',
    key: 'hotTopicText',
    mappingName: 'HotTopicText',
  },
  {
    description: '熱門話題(圖)',
    code: 'hot_topic_image',
    key: 'hotTopicImage',
    mappingName: 'HotTopicImage',
  },
  {
    description: '流行熱搜',
    code: 'hot_search',
    key: 'hotSearch',
    mappingName: 'HotSearch',
  },
  {
    description: '會員小B',
    code: 'member_little_b',
    key: 'memberBanner',
    mappingName: 'MemberBanner',
  },
  {
    description: '好康優惠大B',
    code: 'discount_big_b',
    key: 'discountBannerLarge',
    mappingName: 'DiscountBannerLarge',
  },
  {
    description: '好康優惠小B',
    code: 'discount_little_b',
    key: 'discountBannerSmall',
    mappingName: 'DiscountBannerSmall',
  },
  {
    description: '好康優惠專區',
    code: 'discount_area',
    key: 'discountArea',
    mappingName: 'DiscountArea',
  },
  {
    description: '橫幅',
    code: 'home_page_horizontal_paint',
    key: 'horizontalPaint',
    mappingName: 'HorizontalPaint',
  },
  {
    description: '主題企劃',
    code: 'home_page_main_topic_project',
    key: 'mainTopicProject',
    mappingName: 'MainTopicProject',
  },
  {
    description: '品牌週',
    code: 'brand_week',
    key: 'weeklyBrands',
    mappingName: 'WeeklyBrands',
  },
  {
    description: '品牌列表',
    code: 'brand_listing',
    key: 'brandListing',
    mappingName: 'BrandListing',
  },
  {
    description: '線別',
    code: 'thread_group',
    key: 'threadGroups',
    mappingName: 'ThreadGroups',
  },
  {
    description: '誠品獨家',
    code: 'eslite_exclusive',
    key: 'esliteExclusive',
    mappingName: 'EsliteExclusive',
  },
  {
    description: '誠品選書',
    code: 'book_of_eslite_choice',
    key: 'esliteBook',
    mappingName: 'EsliteBook',
  },
  {
    description: '誠品選樂',
    code: 'music_of_eslite_choice',
    key: 'esliteMusic',
    mappingName: 'EsliteMusic',
  },
  {
    description: '網路暢銷榜',
    code: 'home_page_online_leaderboard',
    key: 'onlineLeaderboard',
    mappingName: 'OnlineLeaderboard',
  },
];

/**
 * 首頁廣告「類型」
 * {object} homePageADTypeKey { key: code } 組合
 * @sample bigBanner: 'big_b_new'
 * =============================================
 * @property bigBanner
 * @property focusPoint
 * @property groupRecommend
 * @property hotTopicText
 * @property hotTopicImage
 * @property hotSearch
 * @property memberBanner
 * @property discountBannerLarge
 * @property discountBannerSmall
 * @property discountArea
 * @property horizontalPaint
 * @property mainTopicProject
 * @property weeklyBrands
 * @property threadBannerSmall
 * @property threadProducts
 * @property threadKeywords
 * @property esliteBook
 * @property esliteExclusive
 * @property esliteMusic
 * @property onlineLeaderboard
 * @property brandListing
 * */
export const homePageADTypeKey = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.key], [x.key]);
    }, homePageAD),
  );
})();

/**
 * 首頁廣告「類型」
 * {object} homePageADTypeEnum { key: code } 組合
 * @sample bigBanner: 'big_b_new'
 * =============================================
 * @property bigBanner
 * @property focusPoint
 * @property groupRecommend
 * @property hotTopicText
 * @property hotTopicImage
 * @property hotSearch
 * @property memberBanner
 * @property discountBannerLarge
 * @property discountBannerSmall
 * @property discountArea
 * @property horizontalPaint
 * @property mainTopicProject
 * @property weeklyBrands
 * @property threadBannerSmall
 * @property threadProducts
 * @property threadKeywords
 * */
export const homePageADTypeEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.key], [x.code]);
    }, homePageAD),
  );
})();

/**
 * 首頁廣告「Mapping」
 *   用來對應 method name
 * {object} homePageADTypeEnum { code: mappingName } 組合
 * @sample BigBanner: 'big_b_new'
 * =============================================
 * @property BigBanner
 * @property FocusPoint
 * @property GroupRecommend
 * @property HotTopicText
 * @property HotTopicImage
 * @property HotSearch
 * @property MemberBanner
 * @property DiscountBannerLarge
 * @property DiscountBannerSmall
 * @property DiscountArea
 * @property HorizontalPaint
 * @property MainTopicProject
 * @property WeeklyBrands
 * @property ThreadBannerSmall
 * @property ThreadProducts
 * @property ThreadKeywords
 * */
export const homePageADMappingEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.code], [x.mappingName]);
    }, homePageAD),
  );
})();

/**
 * 首頁廣告「Mapping」
 *   用來對應 method name
 * {object} homePageADTypeEnum { code: key } 組合
 * @sample 'big_b_new': bigBanner
 * */
export const homePageADKeyEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.code], [x.key]);
    }, homePageAD),
  );
})();

export const esliteExclusiveEnum = {
  [homePageADTypeKey.esliteExclusive]: {
    key: homePageADTypeKey.esliteExclusive,
    hat: 'eslite_only',
    siteLink: '/eslite-only',
    wording: '誠品獨家',
  },
  [homePageADTypeKey.esliteBook]: {
    key: homePageADTypeKey.esliteBook,
    hat: 'eslite_book',
    siteLink: '/eslite-choice-books',
    wording: '誠品選書',
  },
  [homePageADTypeKey.esliteMusic]: {
    key: homePageADTypeKey.esliteMusic,
    hat: 'eslite_music',
    siteLink: '/eslite-choice-music',
    wording: '誠品選樂',
  },
};

const offsetItem = (offset) => (value) => offset + value;

export function incrementItem(item) {
  return item + 1;
}

export function offsetIncrementItem(item, offset = 0) {
  return offsetItem(offset)(item + 1);
}

export function composeCategoryGtmPrefixMaker(routeName) {
  const pageLevel = routeName.replace(/category-([1,2,3])-id/, '$1');
  return (key) => `general-l${pageLevel}-${key}-`;
}

<template>
  <div class="percentage-discount-price static-percentage-price tw-flex tw-items-baseline">
    <span v-if="discount" class="discount bold">{{ discount }}</span>
    <span v-if="discount" class="zhe">折,</span>
    <span v-if="!isZero" class="slider-price">{{ priceFormat(price) }}</span>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';
import priceFormat from '@/helper/filter/price-format';

const props = {
  showPrice: {
    type: Boolean,
    default: true,
  },
  guid: {
    type: String,
    default: '',
  },
  price: {
    type: Number,
    default: null,
  },
  discount: {
    type: String,
    default: '',
  },
  allowZero: {
    type: Boolean,
    default: false,
  },
};

export default {
  name: 'StaticPercentagePrice',
  props,
  setup(props) {
    const { allowZero, price } = toRefs(props);

    const isZero = computed(() => {
      if (allowZero.value === true) return false;
      return price.value === 0;
    });

    return {
      isZero,
      priceFormat,
    };
  },
};
</script>

<style lang="scss" scoped>
.percentage-discount-price {
  @include fontSize(18);
}
.discount {
  font-size: inherit;
  color: var(--e-light-red-d0);
}
.zhe {
  @include fontSize(12);
  display: inline-block; //for word-break
  transform: translateY(1px);
  margin: 1px 2px;
  color: var(--e-light-red-d0);
}
.slider-price::before {
  @include fontSize(11);
}
#virtual-shelf-layout {
  .discount,
  .zhe,
  .slider-price {
    color: var(--e-light-red-d0) !important;
  }
}
@include mediaMax($grid-breakpoints-sm) {
  .percentage-discount-price {
    @include fontSize(16);
  }
}
@include mediaIs($grid-breakpoints-md) {
  .percentage-discount-price {
    @include font20;
  }
  .slider-price::before {
    @include fontSize(12);
  }
}
</style>

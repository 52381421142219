export const displayStyleEnum = {
  list: 'list',
  square: 'square',
};

export const discountDisplayMode = {
  badge: { badge: true, 'bg-eslite-red': true },
  wording: '',
};

export const styleDisplayClass = {
  [displayStyleEnum.list]: discountDisplayMode.badge,
  [displayStyleEnum.square]: discountDisplayMode.wording,
};

export const formatRuleTypeEnum = {
  wording: 'wording',
  priceOnly: 'priceOnly',
};

<template>
  <base-product :product="product" :product-gtm-id="productGtmId">
    <template #product-image>
      <product-swiper-image :src="product.image" :is-adult="product.isAdult" :alt="product.title || product.name"></product-swiper-image>
    </template>
    <template #custom-part>
      <div class="title ellipsis" :title="product.title || product.name">{{ product.title || product.name }}</div>
      <hr />
    </template>
  </base-product>
</template>

<script>
import BaseProduct from '@/components/utility/product/base-product.vue';
import ProductLazyImage from '@/components/utility/product/image/product-lazy-image.vue';
import ProductSwiperImage from '@/components/utility/product/image/product-swiper-image.vue';

const components = {
  BaseProduct,
  ProductLazyImage,
  ProductSwiperImage,
};

const props = {
  /** normal */
  // 刷新價格、component 元件的 flag (用於:key)
  refreshFlag: {
    type: Number,
    default: 0,
  },
  // 商品資料主體
  product: {
    type: Object,
    default: () => [],
  },
  productGtmId: {
    type: String,
    default: '',
  },
  /** hat 相關 */
  isHatSingleLineHeight: {
    type: Boolean,
    default: false,
  },
};
export default {
  name: 'ProductWithTitle',
  components,
  props,
};
</script>

<style lang="scss" scoped>
.title {
  color: var(--e-light-gold);
  @include fontSize(16);
  line-height: 1.33rem;
  height: 1.33rem; // for skeleton
}
hr {
  border-top: 1px var(--l-gray-e) solid;
  margin-top: 4px;
  margin-bottom: 4px;
}
/* 省略字專用 */
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>

/**
 * productStatusEnum
 * 商品狀態 Enum
 * @type {{notices: string, notStart: string, outOfPrint: string, ended: string, selling: string, disabled: string, alreadyNotice: string}}
 */
export const productStatusEnum = {
  selling: 'add_to_shopping_cart',
  outOfPrint: 'out_of_print',
  disabled: 'can_not_buy',
  notStart: 'has_not_started',
  ended: 'has_ended',
  noStock: 'not_add_to_notice',
  notices: 'not_notify',
  alreadyNotice: 'restock_notify',
};

/**
 * 各狀態對應的中文 wording
 * @type {{[p: string]: string}}
 */
export const productStatusWording = {
  [productStatusEnum.selling]: '加入購物車',
  [productStatusEnum.outOfPrint]: '已絕版',
  [productStatusEnum.disabled]: '無法購買',
  [productStatusEnum.notStart]: '尚未開賣',
  [productStatusEnum.ended]: '銷售結束',
  [productStatusEnum.noStock]: '貨到通知', // '存貨盤點中',
  [productStatusEnum.notices]: '設定成功，貨到將通知您',
  [productStatusEnum.alreadyNotice]: '貨到通知',
};

export const productStatusSimpleWording = {
  ...productStatusWording,
  [productStatusEnum.notices]: '通知設定成功',
};

/**
 * 是否可購買／通知
 * @type {{[p: string]: boolean}}
 */
export const productStatusIsEnable = {
  [productStatusEnum.selling]: true,
  [productStatusEnum.outOfPrint]: false,
  [productStatusEnum.disabled]: false,
  [productStatusEnum.notStart]: false,
  [productStatusEnum.ended]: false,
  [productStatusEnum.noStock]: true,
  [productStatusEnum.notices]: false,
  [productStatusEnum.alreadyNotice]: true,
};

/**
 * 是否可收藏
 * @type {{[p: string]: boolean}}
 */
export const productStatusIsCollect = {
  [productStatusEnum.selling]: true,
  [productStatusEnum.outOfPrint]: false,
  [productStatusEnum.disabled]: false,
  [productStatusEnum.notStart]: true,
  [productStatusEnum.ended]: true,
  [productStatusEnum.noStock]: true,
  [productStatusEnum.notices]: false,
  [productStatusEnum.alreadyNotice]: true,
};

<template>
  <div class="product-image" :title="alt" :class="{ 'e-square': isSquare }" @click="$emit('click')" @mouseover="$emit('mouseover')">
    <div v-if="!restricted" class="item-image-wrap" :class="{ 'e-aspect-ratio-inner': isSquare }" :title="alt">
      <img v-if="isLazy && !isSwiperLazy" v-lazy="src" :alt="alt" :style="style" />
      <template v-else>
        <template v-if="!$isEmpty(src)">
          <template v-if="isSwiperLazy"
            ><img class="swiper-lazy" :data-src="src" :alt="alt" :style="style" />
            <div class="swiper-lazy-preloader"></div>
          </template>
          <image-wait-loaded v-else :is-skeleton-image="isSkeletonImage"><img v-default-image="productDefaultImg" :src="src" :alt="alt" :style="style" /></image-wait-loaded></template
        ><img v-else src="@/static/images/product-default@3x.webp" />
      </template>
      <slot name="cover">
        <div class="gray-cover"></div>
      </slot>
    </div>
    <div v-else class="item-image-wrap tw-bg-gray-f4" :class="{ 'e-aspect-ratio-inner': isSquare }">
      <image-wait-loaded :is-skeleton-image="isSkeletonImage"><img src="@/static/images/adult-only.webp" :style="style" /></image-wait-loaded>
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';
import ImageWaitLoaded from '@/components/utility/image/image-wait-loaded.vue';
import { productDefaultImg } from '@/constant/images-path';
import { useLoginStore } from '@/stores/login';

const props = {
  alt: {
    type: String,
    default: '',
  },
  src: {
    type: String,
    default: '',
  },
  isAdult: {
    type: Boolean,
    default: false,
  },
  imageSize: {
    type: Number,
    default: 180,
  },
  isFullSize: {
    type: Boolean,
    default: false,
  },
  isSquare: {
    type: Boolean,
    default: false,
  },
  disableLimit: {
    type: Boolean,
    default: false,
  },
  isLazy: {
    type: Boolean,
    default: true,
  },
  isSwiperLazy: {
    type: Boolean,
    default: false,
  },
  isSkeletonImage: {
    type: Boolean,
    default: false,
  },
};

const setup = (props) => {
  const { disableLimit, isAdult, isFullSize, isSquare, imageSize } = toRefs(props);

  // store
  const loginStore = useLoginStore();

  const restricted = computed(() => {
    // 關閉限制則回傳「不受限」
    if (disableLimit.value === true) return false;
    // 成人會員不受此限制
    const isAdultMember = loginStore.user?.is_adult || false;
    if (isAdultMember) return false;
    // 回傳商品預設限制
    return isAdult.value;
  });
  const style = computed(() => {
    if (isFullSize.value || isSquare.value) {
      return {};
    }
    return {
      width: `${imageSize.value}px`,
      height: `${imageSize.value}px`,
    };
  });
  return {
    productDefaultImg,
    restricted,
    style,
  };
};

export default {
  name: 'ProductImage',
  components: { ImageWaitLoaded },
  props,
  setup,
};
</script>

<style lang="scss" scoped>
.product-image,
.item-image-wrap {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  overflow: hidden;
}
.product-image.e-square {
  height: auto;
}
.item-image-wrap.e-aspect-ratio-inner {
  img {
    position: absolute; // 避免 isSquare 時，safari 圖消失
    top: 0;
    left: 0;
  }
}
.product-image {
  position: relative;
}
.gray-cover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.03);
  pointer-events: none;
}
.item-image-wrap {
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
.swiper-lazy-preloader {
  background-size: contain;
  background-image: url('@/static/images/loading_large.gif');
  background-position: center;
  background-repeat: no-repeat;
  width: inherit;
  height: inherit;
  top: 0;
  left: 0;
  margin: 0;
  animation: none;
  border: none;
}
</style>

<template>
  <div class="image-wait-loaded-wrapper" :class="wrapperClass">
    <slot></slot>
  </div>
</template>

<script>
const props = {
  isSkeletonImage: {
    type: Boolean,
    default: false,
  },
};

const data = function () {
  return {
    isLoaded: false,
  };
};

const computed = {
  imageElement() {
    if (!this.$slots?.default) return false;
    return this.$slots.default[0]?.elm;
  },
  hasSkeletonImage() {
    return this.isSkeletonImage && this.imageElement;
  },
  wrapperClass() {
    if (!this.isSkeletonImage) return {};
    if (this.isLoaded) return {};
    return { 'image-bgcolor-before-loaded': true };
  },
};

const mounted = function () {
  this.$nextTick(this.addImageLoadedListener);
};

const beforeUnmount = function () {
  if (!this.hasSkeletonImage) return;
  this.imageElement.removeEventListener('load', this.imageOnLoadHandler);
};

const methods = {
  imageOnLoadHandler() {
    this.isLoaded = true;
  },
  addImageLoadedListener() {
    if (!this.hasSkeletonImage) {
      this.isLoaded = true;
      return;
    }
    this.isLoaded = false;
    this.imageElement.addEventListener('load', this.imageOnLoadHandler);
  },
};

export default {
  name: 'ImageWaitLoaded',
  props,
  data,
  computed,
  mounted,
  beforeUnmount,
  methods,
};
</script>

<style lang="scss" scoped>
.image-wait-loaded-wrapper {
  width: 100%;
  height: 100%;
}
.image-bgcolor-before-loaded {
  :deep(img) {
    background-color: var(--e-skeleton-bg);
  }
}
</style>

import { forEachObjIndexed, length } from 'ramda';
import * as yup from 'yup';
import { baseProductSchema } from '@/schema/api/ad/home-page/common';
import { objectRemoveKeys } from '@/helper/data-process';

const schema = yup.array().of(baseProductSchema);

// 用一個 method 保持 `yup` interface 一致
export const productGroupsValidateSync = async (data) => {
  // 移除 key，不影響 reference 先解構一次。
  const response = { ...data };
  const type = data.banner_type;
  objectRemoveKeys(response)(['page', 'blank', 'banner_type']);
  // validate error
  const errorSummary = [];
  await forEachObjIndexed(async (value, key) => {
    try {
      await schema.validateSync(value);
    } catch (error) {
      error.message = `banner[${type}] : errorKey = ${key}, errorMessage = ${error.message}`;
      errorSummary.push(error);
    }
  }, response);
  // 統整錯誤並拋出
  if (length(errorSummary) > 0) throw new Error(errorSummary);
};

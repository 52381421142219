<template>
  <a :href="virtualShelfSupportLink" :target="formattedTarget"><slot></slot></a>
</template>

<script>
import { toRefs, computed } from 'vue';
import { virtualShelfNotSupportHash, virtualShelfNotSupportHomeLink } from '@/constant/virtual-shelf';
import { checkVirtualShelfSupportLink, linkSupportVirtualShelf, linkTargetSupportVirtualShelfAndSubframe } from '@/helper/virtual-shelf';
import virtualShelfComposable from '@/composables/utility/virtual-shelf';

const props = {
  href: {
    type: String,
    default: '',
  },
  target: {
    type: String,
    default: '_self',
  },
};

const setup = (props) => {
  const { href, target } = toRefs(props);

  const { isSubframe, isUseVirtualShelfLink } = virtualShelfComposable();

  const virtualShelfSupportLink = computed(() => {
    if (!isUseVirtualShelfLink.value) return href.value;
    // 虛擬貨架不支援之外部連結
    if (!checkVirtualShelfSupportLink(href.value)) {
      // 以 sub frame 開啟，_parent 導到 首頁 #virtual-shelf-not-support-at-home，顯示此頁不支援
      if (isSubframe.value) return virtualShelfNotSupportHomeLink;
      // 導到 #virtual-shelf-not-support，顯示此頁不支援
      return virtualShelfNotSupportHash;
    }
    const newLink = linkSupportVirtualShelf(href.value);
    if (isSubframe.value) return newLink;
    return newLink.replace(import.meta.env.VITE_BASE_URL, '');
  });
  const formattedTarget = computed(() => linkTargetSupportVirtualShelfAndSubframe({ target: target.value, isUseVirtualShelfLink: isUseVirtualShelfLink.value, isSubframe: isSubframe.value }));

  return {
    virtualShelfSupportLink,
    formattedTarget,
  };
};

export default {
  name: 'ALinkUsage',
  props,
  setup,
};
</script>

import { computed, inject, toRef } from 'vue';
import { containerWidth } from '@/constant/breakpoint';
import virtualShelf from '@/composables/utility/virtual-shelf';

export function useProductItemWidth({ productWidth, freeWidth }) {
  const productWidthRef = toRef(productWidth);
  const freeWidthRef = toRef(freeWidth);
  const { isVirtualShelfLayout } = virtualShelf();
  const $screen = inject('$screen');
  // computed
  const defaultSize = computed(() => {
    if (isVirtualShelfLayout.value) return 815 / 2;
    if ($screen.isExtraSmallSize) return 530 / 3;
    if ($screen.isSmallSize) return 530 * (containerWidth.small / 1080);
    if ($screen.isMediumSize) return 530 * (containerWidth.medium / 1080);
    if ($screen.isDesktopSize) return 180;
    return 0;
  });

  const productSize = computed(() => {
    if (!productWidthRef.value) return defaultSize.value;
    return productWidthRef.value;
  });

  const productStyle = computed(() => {
    if (freeWidthRef.value === true) return {};
    // notices : 不建議做客製化修改，因為要讓圖片寬度=商品寬度
    return {
      width: `${productSize.value}px`,
      // 防止超過 slide 寬度 (常出現在992px)
      'max-width': '100%',
    };
  });

  return {
    productSize,
    productStyle,
  };
}

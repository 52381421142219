import axios from 'axios';
import { getCurrentDateAndHour } from '@/helper/filter/date';

const axiosConfig = {
  withCredentials: false,
  timeout: 15 * 1000,
  retry: 2,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export default {
  getCategories() {
    return axios.get(`${import.meta.env.VITE_API}${import.meta.env.VITE_API_CATEGORIES}?date=${getCurrentDateAndHour()}`, axiosConfig);
  },
  getCategory(id) {
    return axios.get(`${import.meta.env.VITE_API}${import.meta.env.VITE_API_CATEGORIES}/${id}?date=${getCurrentDateAndHour()}`, axiosConfig);
  },
};

import { splitEvery, forEach } from 'ramda';

const queueMax = 20;
const flushPeriod = 60; // millisecond

export default class PriceQueue {
  constructor(fetchPrice) {
    this.fetchPrice = fetchPrice;
    this.productIdQueue = [];
    this.flushTimeoutId = null;
    this.flushQueue = this.flushQueue.bind(this);
  }

  flushQueue() {
    clearTimeout(this.flushTimeoutId);
    this.flushTimeoutId = null;
    const groups = splitEvery(queueMax, this.productIdQueue);
    this.productIdQueue = [];
    forEach(this.fetchPrice, groups);
  }

  delayFlushQueue() {
    if (this.flushTimeoutId) return;
    this.flushTimeoutId = setTimeout(this.flushQueue, flushPeriod);
  }

  pushProductIds(productIds) {
    this.productIdQueue.push(...productIds);
    if (this.productIdQueue.length >= queueMax) {
      this.flushQueue();
      return;
    }
    this.delayFlushQueue();
  }
}

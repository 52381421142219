import { split, map, pipe, filter, includes, keys } from 'ramda';
import { homePageADTypeEnum } from '@/constant/ad/homepage-ad-type';
// screen 1
import { homePageAdBigBNewSchema } from '@/schema/api/ad/home-page/big-b-new';
import { homePageFocusPointSchema } from '@/schema/api/ad/home-page/focus-point';
import { homePageGroupRecommend } from '@/schema/api/ad/home-page/home-page-recommend-of-the-day-by-tabs';
import { homePageHotSearch } from '@/schema/api/ad/home-page/hot-search';
import { homePageMemberLittleBanner } from '@/schema/api/ad/home-page/member-little-b';
// screen 2
import { homePageDiscountBigBannerSchema } from '@/schema/api/ad/home-page/discount-big-b';
import { homePageDiscountLittleBannerSchema } from '@/schema/api/ad/home-page/discount-little-b';
import { homePageDiscountAreaSchema } from '@/schema/api/ad/home-page/discount-area';
import { homePageHorizontalPaintSchema } from '@/schema/api/ad/home-page/home-page-horizontal-paint';
// screen 3
import { homePageExclusiveSchema } from '@/schema/api/ad/home-page/eslite-exclusive';
import { homePageEsliteBookSchema } from '@/schema/api/ad/home-page/book-of-eslite-choice';
import { homePageEsliteMusicSchema } from '@/schema/api/ad/home-page/music-of-eslite-choice';
// screen 4
import { homePageBrandWeekSchema } from '@/schema/api/ad/home-page/brand-week';
import { homePageMainTopicProjectSchema } from '@/schema/api/ad/home-page/home-page-main-topic-project';
import { homePageBrandListSchema } from '@/schema/api/ad/home-page/brand-listing';
import { homePageOnlineLeaderboardSchema } from '@/schema/api/ad/home-page/home-page-online-leaderboard';
// screen 5
import { homePageThreadGroupSchema } from '@/schema/api/ad/home-page/thread-group';

const homePageAdSchema = {
  // screen 1
  [homePageADTypeEnum.bigBanner]: homePageAdBigBNewSchema,
  [homePageADTypeEnum.focusPoint]: homePageFocusPointSchema,
  [homePageADTypeEnum.groupRecommend]: homePageGroupRecommend,
  [homePageADTypeEnum.hotSearch]: homePageHotSearch,
  [homePageADTypeEnum.memberBanner]: homePageMemberLittleBanner,
  // screen 2
  [homePageADTypeEnum.discountBannerLarge]: homePageDiscountBigBannerSchema,
  [homePageADTypeEnum.discountBannerSmall]: homePageDiscountLittleBannerSchema,
  [homePageADTypeEnum.discountArea]: homePageDiscountAreaSchema,
  [homePageADTypeEnum.horizontalPaint]: homePageHorizontalPaintSchema,
  // screen 3
  [homePageADTypeEnum.onlineLeaderboard]: homePageOnlineLeaderboardSchema,
  [homePageADTypeEnum.esliteExclusive]: homePageExclusiveSchema,
  [homePageADTypeEnum.esliteBook]: homePageEsliteBookSchema,
  [homePageADTypeEnum.esliteMusic]: homePageEsliteMusicSchema,
  // screen 4
  [homePageADTypeEnum.mainTopicProject]: homePageMainTopicProjectSchema,
  [homePageADTypeEnum.weeklyBrands]: homePageBrandWeekSchema,
  // screen 5
  [homePageADTypeEnum.threadGroups]: homePageThreadGroupSchema,
};

// 根據 type 取出 schema
const getBoardMappingSchema = map((key) => ({ key, schema: homePageAdSchema[key] }));
// 過濾使用到的 schema
const isInSchemaList = (x) => includes(x, keys(homePageAdSchema));
const filterSchema = filter((x) => isInSchemaList(x));

// export
export const getHomePageAdSchema = pipe(split(','), filterSchema, getBoardMappingSchema);

<template>
  <div class="recommend-swiper-desktop">
    <!-- 標題 tabs-->
    <recommend-tabs :data="groupTitles" :value="currentGroupIndex" @select="select"></recommend-tabs>
    <recommend v-if="!hasDailyRecommend" data-test-id="skeleton"></recommend>
    <swiper v-if="hasDailyRecommend" ref="slidersRef" class="swiper pagination-red" :options="swiperOption" @slideChange="slideChangeHandler">
      <swiper-slide v-for="(subProducts, index) in groupProducts" :key="`recommend-slide-${index}`" class="swiper-slide">
        <recommend v-if="checkSlideVisible(index)" :products="subProducts.products" :source-i-d="`${sourceID}${incrementItem(index)}-`" :is-skeleton-image="isSkeletonImage"></recommend>
        <div v-else></div>
      </swiper-slide>
      <template v-if="swiperOption.loop" #button-prev>
        <div class="swiper-button-prev e-flex-center"></div>
      </template>
      <template v-if="swiperOption.loop" #button-next>
        <div class="swiper-button-next e-flex-center"></div>
      </template>
      <!--  先註解，留著 debug 頁籤正確性-->
      <!--      <div v-if="swiperOption.loop" #pagination class="swiper-pagination"></div>-->
    </swiper>
  </div>
</template>

<script>
import { ref, computed, watch, nextTick } from 'vue';
import { map } from 'ramda';
import { useGetHomeAdStore } from '@/composables/store/home-ad';
import { imagePath } from '@/helper/ad/ad-helper';
import { randomInteger } from '@/helper/data-process';
import Recommend from '@/components/index/elements/recommend.vue';
import RecommendTabs from '@/components/index/recommend-and-hot-topic-position/recommend-tabs.vue';
import { getSwiperGlobalOptions } from '@/helper/swiper';
import { checkIsImageSnapshotTest } from '@/helper/check-image-snapshot-test';
import { checkIsTest } from '@/helper/test/test-env';
import { incrementItem } from '@/helper/tracking/gtm-id';

const baseSwiperOption = {
  ...getSwiperGlobalOptions(),
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 12,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  loop: true,
};

function getRandomInitialSlideIndex(totalSlide) {
  if (checkIsImageSnapshotTest() || checkIsTest()) {
    return 0;
  }
  return randomInteger(totalSlide);
}

const props = {
  sourceID: String,
  isSkeletonImage: {
    type: Boolean,
    default: false,
  },
};

const setup = () => {
  const { groupRecommend } = useGetHomeAdStore();

  const slidersRef = ref(null);
  const currentGroupIndex = ref(0);
  const isOnlyActive = ref(true);

  const groupTitles = computed(() => map((group) => group?.groupTitle, groupRecommend.value.group || []));
  const groupProducts = computed(() => groupRecommend.value.group || []);
  const hasDailyRecommend = computed(() => groupProducts.value?.length !== undefined && groupProducts.value?.length >= 1);
  const swiperOption = computed(() => {
    const totalSlide = groupProducts.value?.length;
    // initial 對應頁籤
    const initialIndex = getRandomInitialSlideIndex(totalSlide);
    currentGroupIndex.value = initialIndex;

    if (totalSlide > 1) {
      return {
        ...baseSwiperOption,
        updateOnImagesReady: true,
        initialSlide: initialIndex,
      };
    }
    return {
      ...baseSwiperOption,
      loop: false,
      navigation: false,
      pagination: false,
    };
  });

  const getSwiperInstance = () => {
    return slidersRef.value?.$el?.swiper || null;
  };

  const select = (index) => {
    const swiperInstance = getSwiperInstance();
    if (!swiperInstance) return;
    if (swiperOption.value.loop) {
      swiperInstance.slideToLoop(index);
      return;
    }
    swiperInstance.slideTo(index);
  };

  const checkSlideVisible = (index) => {
    if (!isOnlyActive.value) return true;
    return index === currentGroupIndex.value;
  };

  const disableOnlyActive = () => {
    isOnlyActive.value = false;
  };

  const slideChangeHandler = () => {
    disableOnlyActive();
    const swiperInstance = getSwiperInstance();
    currentGroupIndex.value = swiperInstance?.realIndex || 0;
  };

  const resetCurrentSlide = () => {
    const swiperInstance = getSwiperInstance();
    if (!swiperInstance) return;
    if (swiperInstance.realIndex === currentGroupIndex.value) return;
    select(currentGroupIndex.value);
  };

  watch(currentGroupIndex, () => {
    nextTick(() => {
      const swiperInstance = getSwiperInstance();
      if (swiperInstance) {
        resetCurrentSlide();
        return;
      }
      setTimeout(resetCurrentSlide, 0);
    });
  });

  return {
    slidersRef,
    currentGroupIndex,
    groupTitles,
    groupProducts,
    hasDailyRecommend,
    swiperOption,
    // methods
    imagePath,
    incrementItem,
    select,
    checkSlideVisible,
    slideChangeHandler,
    // return for vitest
    getSwiperInstance,
  };
};

export default {
  name: 'RecommendSwiperDesktopV2',
  components: { Recommend, RecommendTabs },
  props,
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/index/daily-recommend.scss';
.all-group-title {
  display: flex;
  .title {
    color: var(--white);
    width: 20%;
    max-width: 20%;
    @include font16;
    .tab {
      @include pxToRem(width, 157);
      @include pxToRem(max-width, 157);
    }
    .tab-content {
      display: flex;
      justify-content: center;
      @include pxToRem(border-radius, 6);
      @include pxToRem(padding, 6, 12);
      @include pxToRem(height, 35);
    }
    .active > .tab-content {
      background-image: linear-gradient(to bottom, #ff7a00, #ff5c00);
    }
    .inactive > .tab-content {
      background-color: #ccc;
    }
  }
}
.tab-arrow {
  border-color: #ff5c00 transparent transparent transparent;
  border-style: solid solid solid solid;

  /* 設定邊框依上、下；左右，各為一群組，相同群組須設定為相同尺寸，調整尺寸可拼湊出任意形狀的三角形 */
  border-width: 10px 10px 45px 10px;

  /* 設定 width、height 可更好理解原理 */
  height: 0px;
  width: 0px;

  //
  position: absolute;
  margin-top: -1px;
  margin-left: calc(157px / 2 - 10px);
}
</style>

<template>
  <div class="percentage-discount-price show-final-price d-flex align-items-end flex-column">
    <div class="final-price">
      <span class="prefix">定價</span>
      <span class="slider-price">{{ priceFormat(prePrice) }}</span>
    </div>
    <div class="retail-price" v-if="showDiscount">
      <span v-if="discount" class="discount">{{ discount }}</span>
      <span v-if="discount" class="zhe">折</span>
      <span v-if="!isZero" class="slider-price">{{ priceFormat(price) }}</span>
    </div>
  </div>
</template>
<script>
import { computed, toRefs } from 'vue';
import priceFormat from '@/helper/filter/price-format';

const props = {
  guid: {
    type: String,
    default: '',
  },
  price: {
    type: Number,
    default: null,
  },
  prePrice: {
    type: Number,
    default: null,
  },
  discount: {
    type: String,
    default: '',
  },
  allowZero: {
    type: Boolean,
    default: false,
  },
};

const setup = (props) => {
  const { allowZero, price, prePrice } = toRefs(props);

  const isZero = computed(() => {
    if (allowZero.value) return false;
    return price.value === 0;
  });
  // 定價跟售價不一樣時，需顯示售價
  const showDiscount = computed(() => prePrice.value !== price.value);

  return {
    isZero,
    showDiscount,
    priceFormat,
  };
};
export default {
  name: 'ShowFinalPrice',
  props,
  setup,
};
</script>
<style lang="scss" scoped>
.show-final-price {
  @include fontSize(20);
  font-weight: bold;
  line-height: 30px;
  min-height: 65px;

  .slider-price::before {
    @include fontSize(14);
    color: var(--e-light-red-d0);
    content: '$';
    font-weight: 500;
    margin-right: 0;
  }

  .final-price {
    color: #333333;
    .prefix {
      font-size: formatSize(14);
      font-weight: 500;
      margin-right: pxToRemValue(4);
    }
    .slider-price,
    .slider-price::before {
      color: #333333;
      letter-spacing: normal;
    }
  }

  .retail-price {
    .discount {
      font-size: inherit;
      color: var(--e-light-red-d0);
    }
    .zhe {
      @include fontSize(14);
      display: inline-block; //for word-break
      color: var(--e-light-red-d0);
      margin-right: pxToRemValue(4);
      font-weight: 500;
    }
    .discount,
    .slider-price {
      letter-spacing: normal;
    }
  }
}

@include mediaMax($grid-breakpoints-lg) {
  .show-final-price {
    min-height: 45px;
  }
}
</style>

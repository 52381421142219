import { dropLast, join, last, length, pipe, split, splitAt } from 'ramda';

// check webp support
// https://developers.google.com/speed/webp/faq

const getWebpPath = (srcPath) => {
  // console.log(imageFullPath);
  const imageFullPath = split('/', srcPath || '');

  // 路徑切段
  const fullPathSplit = pipe(dropLast(1), splitAt(3))(imageFullPath);
  const domain = pipe((x) => x[0], join('/'))(fullPathSplit);
  const dir = pipe(last, join('/'))(fullPathSplit);

  // 取出檔名
  const imageFileName = pipe(last, split('.'), (x) => x[0])(imageFullPath);

  // 是否為 hash path (沒有副檔名)
  const isHashPath = pipe(last, split('.'), (x) => length(x) === 1)(imageFullPath);

  return isHashPath ? `${domain}/webp${dir}/${imageFileName}` : `${domain}/webp/${dir}/${imageFileName}.webp`;
};

// 配合後端 api data 將改為直接提供 webp 圖路徑
export const isOriginSourceWebp = true;

export default {
  convertPath: (srcPath) => (isOriginSourceWebp ? srcPath : getWebpPath(srcPath)),
};

import { mergeAll, map, zipObj } from 'ramda';

/**
 * @const {array} brandEnum brand enum
 */
export const brandLevel2AD = [
  {
    description: '大Banner',
    code: 'brand_l2_big_b',
    key: 'bigBanner',
    mappingName: 'BigBanner',
  },
  {
    description: '品牌圖片',
    code: 'brand_image',
    key: 'brandImage',
    mappingName: 'BrandImage',
  },
  {
    description: '品牌介紹',
    code: 'brand_intro',
    key: 'brandIntro',
    mappingName: 'BrandIntro',
  },
  {
    description: '品牌活動1',
    code: 'brand_event_1',
    key: 'brandEvent1',
    mappingName: 'BrandEvent1',
  },
  {
    description: '品牌活動2',
    code: 'brand_event_2',
    key: 'brandEvent2',
    mappingName: 'BrandEvent2',
  },
  {
    description: '品牌活動3',
    code: 'brand_event_3',
    key: 'brandEvent3',
    mappingName: 'BrandEvent3',
  },
  {
    description: '品牌活動4',
    code: 'brand_event_4',
    key: 'brandEvent4',
    mappingName: 'BrandEvent4',
  },
  {
    description: '品牌活動5',
    code: 'brand_event_5',
    key: 'brandEvent5',
    mappingName: 'BrandEvent5',
  },
  {
    description: '精選分類',
    code: 'feature_kind',
    key: 'featureKind',
    mappingName: 'FeatureKind',
  },
  {
    description: '直立廣告',
    code: 'straight_banner',
    key: 'straightBanner',
    mappingName: 'StraightBanner',
  },
  {
    description: '商品推薦',
    code: 'recommend_products',
    key: 'recommendProducts',
    mappingName: 'RecommendProducts',
  },
  {
    description: '橫幅',
    code: 'brand_l2_horizontal_paint',
    key: 'horizontalPaint',
    mappingName: 'HorizontalPaint',
  },
  {
    description: '重點推薦',
    code: 'brand_emphasize_recommend',
    key: 'emphasizeRecommend',
    mappingName: 'EmphasizeRecommend',
  },
  {
    description: '館長推薦',
    code: 'brand_list',
    key: 'brandList',
    mappingName: 'BrandList',
  },
];

/**
 * 品牌頁廣告「類型」
 * {object} brandLevel2ADTypeEnum { key: code } 組合
 * @sample bigBanner: 'brand_l2_big_b'
 * =============================================
 * */
export const brandLevel2ADTypeEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.key], [x.code]);
    }, brandLevel2AD),
  );
})();

/**
 * 品牌頁廣告「Mapping」
 *   用來對應 method name
 * {object} brandLevel2ADTypeEnum { code: mappingName } 組合
 * @sample BigBanner: 'brand_l2_big_b'
 * =============================================
 * */
export const brandLevel2ADMappingEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.code], [x.mappingName]);
    }, brandLevel2AD),
  );
})();

/**
 * 品牌頁廣告「Mapping」
 *   用來對應 method name
 * {object} brandLevel2ADTypeEnum { code: key } 組合
 * @sample 'brand_l2_big_b': bigBanner
 * */
export const brandLevel2ADKeyEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.code], [x.key]);
    }, brandLevel2AD),
  );
})();

import { esliteOrigin, productPathRegex, eventsEsliteLinkRegex } from '@/constant/path-regex';

function trimPath(path) {
  if (path === '/') return path;
  return path.replace(/\/$/, '');
}

export function getUrlPart(link) {
  const emptyLink = { origin: '', pathname: '', searchParams: '', hash: '' };
  try {
    const { origin, pathname, searchParams, hash } = new URL(link);
    return { origin, pathname: trimPath(pathname), searchParams, hash };
  } catch {
    return emptyLink;
  }
}

export function composeUrlPart({ origin, pathname, searchParams, hash = null }) {
  if (!origin) return `${pathname}?${searchParams.toString()}${hash}`;
  return `${origin}${pathname}?${searchParams.toString()}${hash}`;
}

export function checkEsliteOrigin(origin) {
  return origin === import.meta.env.VITE_BASE_URL || origin === esliteOrigin;
}

function checkEsliteOriginByLink(link) {
  const { origin } = getUrlPart(link);
  return checkEsliteOrigin(origin);
}

export function checkEsliteProductLink(link) {
  const { origin } = getUrlPart(link);
  if (!checkEsliteOrigin(origin)) return false;
  const fullPath = link.split(origin)[1];
  return productPathRegex.test(fullPath);
}

export function checkEsliteOrEventLink(link) {
  return checkEsliteOriginByLink(link) || eventsEsliteLinkRegex.test(link);
}

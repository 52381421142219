<template>
  <swiper ref="littleBannerSwiperRef" class="swiperDefault" :options="swiperOption" :class="{ 'full-size': isFullSize }" :source-i-d="name" @sliderFirstMove="emitChange" @slideChange="slideChange">
    <slot name="swiper-slide" :realIndex="swiperRealIndex">
      <swiper-slide v-for="(item, index) in items" :key="`${name}-${index}`" class="swiper-slide">
        <a :id="sourceID + incrementItem(index)" :href="item.link" :title="item.name" :target="target">
          <img :src="item.image" :srcset="`${item.image} 2x`" :alt="item.name" />
        </a>
      </swiper-slide>
    </slot>
    <template #pagination>
      <div class="swiper-pagination"></div>
    </template>
  </swiper>
</template>

<script>
import { inject, onMounted, reactive, ref, toRef, computed } from 'vue';
import { getSwiperGlobalOptions } from '@/helper/swiper';
import { incrementItem } from '@/helper/tracking/gtm-id';

const props = {
  items: {
    type: Array,
    default: () => [],
  },
  target: {
    type: String,
    default: '_blank',
  },
  name: {
    type: String,
    default: 'big-banner',
  },
  sourceID: String,
  isMobileButtonHidden: {
    type: Boolean,
    default: false,
  },
  isFullSize: {
    type: Boolean,
    default: false,
  },
  isOnlyActive: {
    type: Boolean,
    default: false,
  },
};

const setup = (props, { emit }) => {
  const $screen = inject('$screen');
  const isMobileButtonHidden = toRef(() => props.isMobileButtonHidden);
  const swiperRealIndex = ref(0);
  const littleBannerSwiperRef = ref(null);
  const swiperOption = reactive({
    ...getSwiperGlobalOptions(),
    init: false,
    loop: true,
    slidesPerView: 1,
    grid: {
      rows: 1,
    },
    spaceBetween: 0,
    observer: true,
    observeParents: true,
    mousewheel: false,
    keyboard: true,
    speed: 300,
    pagination: {
      el: '.swiperDefault .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      992: {
        slidesPerView: 1,
        centeredSlides: false,
        spaceBetween: 0,
      },
      0: {
        slidesPerView: 1.25,
        centeredSlides: true,
        spaceBetween: 10,
      },
    },
  });

  const buttonHiddenClass = computed(() => {
    if (!$screen.isMobileSize) return '';
    if (isMobileButtonHidden.value) return 'swiper-button-hidden';
    return '';
  });

  const slideChange = () => {
    swiperRealIndex.value = littleBannerSwiperRef.value?.$el.swiper?.realIndex || 0;
    emit('change', swiperRealIndex.value);
  };
  const emitChange = () => {
    emit('change');
  };

  onMounted(() => littleBannerSwiperRef.value.initSwiperInstance());

  return {
    swiperRealIndex,
    littleBannerSwiperRef,
    swiperOption,
    buttonHiddenClass,
    slideChange,
    emitChange,
    incrementItem,
  };
};

export default {
  name: 'LittleBannerSwiper',
  props,
  emits: ['change'],
  setup,
};
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
  display: none;
  color: var(--white);
  width: 20px;
  height: 36px;
  top: 0;
  bottom: 0;
  margin: auto;
  font-weight: bolder;
  &:after {
    font-size: 34px;
  }
}

.swiper-button-prev {
  @include pxToRem(left, 14);
}

.swiper-button-next {
  @include pxToRem(right, 14);
}

.swiper-slide {
  img {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
}

.full-size {
  .swiper-slide {
    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
@include mediaMax($grid-breakpoints-lg) {
  .swiper-pagination {
    display: none;
  }
}
@include mediaMin($grid-breakpoints-md) {
  // .swiper-button-prev:not(.swiper-button-hidden),
  // .swiper-button-next:not(.swiper-button-hidden) {
  //   display: block;
  // }
  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

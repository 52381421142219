import { map, mergeAll, zipObj } from 'ramda';

export const sortOrder = {
  asc: 'asc',
  desc: 'desc',
};

/**
 * @sample
  {
    name: 顯示名稱,
    code: 值,
    key: KEY,
    enable: 是否可用,
    default: 預設升降冪排序,
    isOrder: 是否可以切換升降冪排序,
    sort: 1 顯示排序,
  },
 * @type {({default: string, code: string, enable: boolean, name: string, sort: number, key: string, isOrder: boolean}|{default: string, code: string, enable: boolean, name: string, sort: number, key: string, isOrder: boolean}|{default: string, code: string, enable: boolean, name: string, sort: number, key: string, isOrder: boolean}|{default: string, code: string, enable: boolean, name: string, sort: number, key: string, isOrder: boolean}|{default: string, code: string, enable: boolean, name: string, sort: number, key: string, isOrder: boolean})[]}
 */
export const searchSortEnum = [
  {
    name: '最相關',
    code: '_weight_',
    key: 'score',
    enable: true,
    default: sortOrder.desc,
    isOrder: false,
    sort: 1,
  },
  {
    name: '最熱銷',
    code: 'hot_rank',
    key: 'hot',
    enable: false,
    default: sortOrder.desc,
    isOrder: false,
    sort: 3,
  },
  {
    name: '價格',
    code: 'price',
    key: 'price',
    default: sortOrder.asc,
    enable: true,
    sort: 5,
    isOrder: true,
    highUnit: '高',
    lowUnit: '低',
  },
  {
    name: '上市日期',
    code: 'manufacturer_date',
    key: 'publishDate',
    default: sortOrder.desc,
    enable: true,
    sort: 7,
    isOrder: true,
    highUnit: '近',
    lowUnit: '遠',
  },
  {
    name: '折扣',
    code: 'discount_range',
    key: 'discount',
    default: sortOrder.asc,
    // #1157 宇匯 phase I 先不做折扣排序：可以追蹤後續是否加回？
    enable: false,
    sort: 9,
    isOrder: true,
    highUnit: '高',
    lowUnit: '低',
  },
];

/**
 * 排序`值`
 * {object} invoiceTypeName { key: code } 組合
 * @sample score: '_score'
 * =============================================
 * @property score
 * @property hot
 * @property price
 * @property publishDate
 * @property discount
 * */
export const searchSortTypeEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.key], [x.code]);
    }, searchSortEnum),
  );
})();

/**
 * 排序`Default 排序值（ASC/DESC）`
 * {object} searchSortOrderEnum { code: default } 組合
 * @sample score: 'desc'
 * =============================================
 * @property score
 * @property hot
 * @property price
 * @property manufacturer_date
 * @property discount_range
 * */
export const searchSortOrderEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.code], [x.default]);
    }, searchSortEnum),
  );
})();

/**
 * searchSortHighLowUnit
 * =============================================
 * @property score
 * @property hot
 * @property price
 * @property publishDate
 * @property discount
 */
export const searchSortHighLowUnit = {
  [searchSortTypeEnum.score]: {
    enable: false,
    high: '',
    low: '',
  },
  [searchSortTypeEnum.hot]: {
    enable: false,
    high: '',
    low: '',
  },
  [searchSortTypeEnum.price]: {
    enable: true,
    high: '高',
    low: '低',
  },
  [searchSortTypeEnum.publishDate]: {
    enable: true,
    high: '近',
    low: '遠',
  },
  [searchSortTypeEnum.discount]: {
    enable: true,
    high: '高',
    low: '低',
  },
};

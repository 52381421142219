export const imageIsExist = function (url) {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = function () {
      if (image.complete !== true) return;
      resolve(true);
    };
    image.onerror = function () {
      resolve(false);
    };
    image.src = url;
  });
};

import * as yup from 'yup';
/**
 * `strictString` 同 test/test-extends/yup-extend.js
 * 但由於 test 不會上 production，這邊拉過來一份
 */
// 同 string().required() 但允許 empty string
yup.addMethod(yup.mixed, 'strictString', function () {
  return this.test('test strict string', 'strictString fail', function (value) {
    const { path, createError } = this;
    return typeof value === 'string' ? this.resolve(true) : createError(Error(`${path} is a required string`));
  });
});

yup.addMethod(yup.string, 'strictString', function () {
  return this.test('test strict string', 'strictString fail', function (value) {
    const { path, createError } = this;
    return typeof value === 'string' ? this.resolve(true) : createError(Error(`${path} is a required string`));
  });
});

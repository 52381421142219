import { isEmpty, isNil } from 'ramda';

const priceFormat = function (price) {
  if (isEmpty(price) || isNil(price)) return '';
  if (typeof price === 'string') price = parseInt(price, 10);
  return price.toLocaleString();
};

export default priceFormat;

export const priceFormatWithDollarSign = function (price) {
  return `$${priceFormat(price)}`;
};

/* eslint-disable no-underscore-dangle */
/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { SwiperSlide } from 'swiper/vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import App from '@/App.vue';
import { initRouter } from '@/router';
import { addPlugins } from '@/plugins/main';
import VSwiper from '@/components/global-components/v-swiper.vue';
import { allStoresInit } from '@/stores/index';

/**
 * plugin 必須在 new Vue 之前執行，否則 plugins/route/index 的 router.beforeEach 在 first load 不會被呼叫。
 * 因為 new Vew 之前，沒有 app，因此建立 pluginContext，提供 plugins 及 router middlewawre 引用
 * (原 nuxt 的 app.$auths、app.$gtm... 改用 pluginContext.$auths、pluginContext.$gtm 取代)
 */

const app = createApp(App);
const pinia = createPinia();
// allow pinia store use globalProperties
pinia.use(({ app, store }) => {
  store.$app = app.config.globalProperties;
});
// 先 use(pinia)，install auths plugin 時執行 verifyTokenAndInitUser 才能使用 pinia store。
app.use(pinia);
allStoresInit();
const { router, isHistoryPopRef } = initRouter();

const pluginContext = {};
await addPlugins({ pluginContext, router, app, isHistoryPopRef, pinia });

app.use(router);
app.use(VueDOMPurifyHTML, {
  default: {
    // whitelists
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'frameborder', 'allowtransparency', 'allowfullscreen'],
  },
});

/**
 * components
 */
app.component('Swiper', VSwiper);
app.component('SwiperSlide', SwiperSlide);

app.provide('$auths', pluginContext.$auths);
app.provide('$cryptoUtility', pluginContext.$cryptoUtility);

app.mount('#app');
// for test
if (import.meta.env.VITE_TYPE !== 'prd') window.esliteApp = app;

import { searchPageQueryEnum } from '@/services/search/search-service';

export const siteQueryKeys = {
  // 版型
  layout: 'layout',
  // 虛擬貨架 店號
  virtualShelfStore: 'merchantid',
  // A/B test
  abTest: 'exp',
};

export const layoutQueryEnum = {
  virtualShelf: 'virtual_shelf',
};

const esliteShareableQueryParamEnum = {
  ...searchPageQueryEnum,
};

export const esliteShareableQueryParamList = Object.values(esliteShareableQueryParamEnum);

/**
 * AB Test example
 * a: 10%
 * b: 10%
 * origin: 80% => 不在 AB Test 統計範圍
 */
export const abTestQueryEnum = {
  a: 'a',
  b: 'b',
  origin: 'o',
};

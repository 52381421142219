import { siteQueryKeys, layoutQueryEnum } from '@/constant/site-query-string';
import { appendQueryString } from '@/helper/url/query-string';
import { checkSubframe } from '@/helper/link-target';

export function checkVirtualShelf() {
  const urlParams = new URLSearchParams(window.location.search);
  const layout = urlParams.get(siteQueryKeys.layout);
  return layout === layoutQueryEnum.virtualShelf;
}

export function checkVirtualShelfSupportLink(link) {
  if (link.indexOf('/') === 0) return true;
  if (link.indexOf(import.meta.env.VITE_BASE_URL) === 0) return true;
  // 活動頁 支援虛擬貨架，會加 上下一頁 控制鍵
  if (link.indexOf('https://events.eslite.com') === 0) return true;
  if (link.indexOf('https://www.eslite.com') === 0) return true;
  return false;
}

export const linkSupportVirtualShelf = (link) => {
  // anchor 不需處理
  if (link.indexOf('#') === 0) return link;
  // 外部連結，不加 虛擬貨架 query string
  if (!checkVirtualShelfSupportLink(link)) return link;
  return appendVirtualShelfQueryString(link);
};

// 虛擬貨架 固定 _self，因為 1. 頁面控制鍵(上下頁) 2.方便 middleware 帶 query string
export const linkTargetSupportVirtualShelfAndSubframe = ({ target, isUseVirtualShelfLink, isSubframe }) => {
  if (isUseVirtualShelfLink) {
    if (isSubframe) return '_parent';
    return '_self';
  }
  if (isSubframe) return '_blank';
  return target === true || target === '_blank' ? '_blank' : '_self';
};

export function appendVirtualShelfQueryString(link) {
  const urlParams = new URLSearchParams(window.location.search);
  const merchantId = urlParams.get(siteQueryKeys.virtualShelfStore);
  const queryStrings = {
    [siteQueryKeys.layout]: layoutQueryEnum.virtualShelf,
    [siteQueryKeys.virtualShelfStore]: merchantId,
  };
  return appendQueryString({ link, queryStrings });
}

// pages/event-embedded/ 活動嵌入頁 不使用 virtual-shelf layout，但 a-link 需支援 virtual-shelf
export function checkVirtualShelfLayout() {
  return checkVirtualShelf() && !checkSubframe();
}

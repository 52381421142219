import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { siteQueryKeys, layoutQueryEnum } from '@/constant/site-query-string';
import { checkSubframe } from '@/helper/link-target';

export default function () {
  const route = useRoute();
  const isSubframe = ref(checkSubframe());
  const isUseVirtualShelfLink = computed(() => {
    // 允許測試沒有 mock $route
    if (!route?.query) return false;
    return route?.query[siteQueryKeys.layout] === layoutQueryEnum.virtualShelf;
  });
  // pages/event-embedded/ 活動嵌入頁 不使用 virtual-shelf layout，但 a-link 需支援 virtual-shelf
  const isVirtualShelfLayout = computed(() => {
    return isUseVirtualShelfLink.value && !isSubframe.value;
  });
  const notVirtualShelfLayout = computed(() => {
    return !isVirtualShelfLayout.value;
  });

  return {
    isSubframe,
    isUseVirtualShelfLink,
    isVirtualShelfLayout,
    notVirtualShelfLayout,
  };
}

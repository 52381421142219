<template>
  <div ref="navWrapper" class="scroll-active">
    <slot></slot>
  </div>
</template>

<script>
import { ref, toRefs, computed, onUnmounted, onMounted, nextTick, watch } from 'vue';
import throttle from 'lodash-es/throttle';
import { useRoute } from 'vue-router';
import { firstElementInScreenByIds, isElementTopBelowWindowTop } from '@/helper/scroll';

const props = {
  activeClass: {
    type: String,
    default: 'active',
  },
  hasDefaultActive: {
    type: Boolean,
    default: false,
  },
  isPause: {
    type: Boolean,
    default: false,
  },
  isReady: {
    type: Boolean,
    default: false,
  },
};

// 取得 href 的 hash
const getHashOfHref = (href) => {
  const emptyHash = '';
  if (!href) return emptyHash;
  const hashValue = href.split('#')[1];
  if (!hashValue) return emptyHash;
  return `#${hashValue}`;
};

// 取得 element (1)data-hash值 或 (2)自己或子元件 href 的 hash
const getDataOrHrefHashOfElement = (element) => {
  if (element.dataset.hash) {
    return element.dataset.hash;
  }
  if (element.hasAttribute('href')) {
    return getHashOfHref(element.getAttribute('href'));
  }
  const href = element.querySelector('[href]:not([href=""])')?.href;
  return getHashOfHref(href);
};

// 判斷 element (1)data-hash 與 hash 相符 或 (2)自己或子元件 href 結尾與 hash 相符
const isElementMatchHash = ({ element, hash }) => {
  if (element.dataset.hash === hash) return true;
  return new RegExp(`${hash}$`).test(element.getAttribute('href')) || element.querySelector(`[href$="${hash}"]`);
};

const setup = (props) => {
  const route = useRoute();
  const { activeClass, hasDefaultActive, isPause, isReady } = toRefs(props);
  const hashList = ref([]);
  const selectedHash = ref('');
  const resizeObserver = ref(null);
  const navWrapper = ref(null);

  /**
   * Computed
   */
  const hashIdList = computed(() => hashList.value.map((hash) => hash.split('#')[1]));
  // eslint-disable-next-line no-use-before-define
  const onScrollHandler = computed(() => throttle(onScrollHandlerNoThrottle, 100));

  /**
   * Methods
   */
  const setHashList = () => {
    if (!navWrapper.value) return;
    const childElements = navWrapper.value.children;
    if (!childElements?.length) return;
    hashList.value = Object.values(childElements)
      .map((element) => getDataOrHrefHashOfElement(element))
      .filter((hash) => hash);
  };
  const setDefaultActiveClassForEmptySelected = () => {
    const firstChild = navWrapper.value?.children[0];
    if (!firstChild) return;
    if (isElementTopBelowWindowTop(firstChild)) {
      firstChild.classList.add(activeClass.value);
      return;
    }
    const childLength = navWrapper.value?.children.length;
    const lastChild = navWrapper.value?.children[childLength - 1];
    lastChild.classList.add(activeClass.value);
  };
  const updateSelectedHash = (newHash) => {
    if (selectedHash.value === newHash) return;
    if (!navWrapper.value) return;
    selectedHash.value = newHash || '';
    const childElements = navWrapper.value.children;
    if (!newHash) {
      Object.values(childElements).forEach((child) => child.classList.remove(activeClass.value));
      if (!hasDefaultActive.value) return;
      setDefaultActiveClassForEmptySelected();
      return;
    }
    Object.values(childElements).forEach((child) => {
      const match = isElementMatchHash({ element: child, hash: newHash });
      if (match) {
        child.classList.add(activeClass.value);
        return;
      }
      child.classList.remove(activeClass.value);
    });
  };
  const onScrollHandlerNoThrottle = () => {
    if (isPause.value) return;
    if (!hashList.value?.length) return;
    const elementId = firstElementInScreenByIds(hashIdList.value);
    const newHash = elementId ? `#${elementId}` : '';
    updateSelectedHash(newHash);
  };
  const addListener = () => {
    window.addEventListener('scroll', onScrollHandler.value);
    if (window.ResizeObserver) {
      resizeObserver.value = new ResizeObserver(onScrollHandler.value);
      resizeObserver.value.observe(document.body);
      return;
    }
    window.addEventListener('resize', onScrollHandler.value);
  };
  const removeListener = () => {
    window.removeEventListener('scroll', onScrollHandler.value);
    if (resizeObserver.value) {
      resizeObserver.value.disconnect();
      return;
    }
    window.removeEventListener('resize', onScrollHandler.value);
  };
  const init = () => {
    setHashList();
    if (!hashList.value.length) return;
    if (!route.hash) setDefaultActiveClassForEmptySelected();
    onScrollHandler.value();
    addListener();
  };

  onUnmounted(() => {
    removeListener();
  });
  onMounted(async () => {
    await nextTick();
    init();
  });

  watch(
    () => isReady.value,
    async (value) => {
      if (!value) return;
      await nextTick();
      onScrollHandler.value();
    },
  );

  return {
    navWrapper,
  };
};

export default {
  props,
  setup,
};
</script>

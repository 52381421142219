<template>
  <swiper ref="bigBannerSwiperRef" class="swiperDefault" :options="swiperOption" :class="{ 'full-size': isFullSize }" :source-i-d="name" @slideChange="slideChange">
    <swiper-slide v-for="(item, index) in items" :key="`${name}-${index}`" class="swiper-slide">
      <a-link-usage :id="sourceID + incrementItem(index)" :href="item.link" :title="item.alt" :target="target">
        <swiper-webp-image v-if="isLazy && index !== initialIndex" :src="item.image" :alt="item.alt"></swiper-webp-image>
        <webp-image v-else :src="item.image" :alt="item.alt"></webp-image>
      </a-link-usage>
    </swiper-slide>
    <template #button-prev>
      <div class="swiper-button-prev" :class="buttonHiddenClass"></div>
    </template>
    <template #button-next>
      <div class="swiper-button-next" :class="buttonHiddenClass"></div>
    </template>
    <template #pagination>
      <div class="swiper-pagination"></div>
    </template>
  </swiper>
</template>

<script>
import { inject, nextTick, onMounted, reactive, ref, toRefs, computed } from 'vue';
import ALinkUsage from '@/components/utility/a-link-usage.vue';
import { getSwiperGlobalOptions, modifySwiperOptionForVisualTest } from '@/helper/swiper';
import SwiperWebpImage from '@/components/utility/image/swiper-webp-image.vue';
import WebpImage from '@/components/utility/image/webp-image.vue';
import { incrementItem } from '@/helper/tracking/gtm-id';

const props = {
  items: {
    type: Array,
    default: () => [],
  },
  target: {
    type: String,
    default: '_blank',
  },
  name: {
    type: String,
    default: 'big-banner',
  },
  sourceID: String,
  isMobileButtonHidden: {
    type: Boolean,
    default: false,
  },
  isFullSize: {
    type: Boolean,
    default: false,
  },
  isLazy: {
    type: Boolean,
    default: true,
  },
};

const setup = (props, { emit }) => {
  const $screen = inject('$screen');
  const { items, isLazy, isMobileButtonHidden } = toRefs(props);
  const swiperRealIndex = ref(0);
  const initialIndex = ref(0);
  const bigBannerSwiperRef = ref(null);
  const swiperOption = reactive(
    modifySwiperOptionForVisualTest({
      ...getSwiperGlobalOptions(),
      init: false,
      loop: items.value.length > 1,
      slidesPerView: 1,
      spaceBetween: 0,
      observer: true,
      observeParents: true,
      mousewheel: false,
      keyboard: true,
      speed: 300,
      preloadImages: true,
      lazyPreloadPrevNext: isLazy.value ? 1 : 0,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        reverseDirection: false,
        waitForTransition: true,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiperDefault .swiper-button-next',
        prevEl: '.swiperDefault .swiper-button-prev',
      },
      pagination: {
        el: '.swiperDefault .swiper-pagination',
        clickable: true,
      },
    }),
  );

  const swiper = computed(() => bigBannerSwiperRef.value?.$el.swiper);
  const buttonHiddenClass = computed(() => {
    if (!$screen.isMobileSize) return '';
    if (isMobileButtonHidden.value) return 'swiper-button-hidden';
    return '';
  });

  const slideChange = () => {
    swiperRealIndex.value = swiper.value?.realIndex || 0;
    emit('change', swiperRealIndex.value);
  };

  onMounted(async () => {
    await nextTick();
    bigBannerSwiperRef.value.initSwiperInstance();
  });

  return {
    swiperRealIndex,
    initialIndex,
    bigBannerSwiperRef,
    swiperOption,
    buttonHiddenClass,
    slideChange,
    incrementItem,
  };
};

export default {
  name: 'BigBannerSwiper',
  components: { ALinkUsage, SwiperWebpImage, WebpImage },
  props,
  setup,
};
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
  display: none;
  color: var(--white);
  width: 20px;
  height: 36px;
  top: 0;
  bottom: 0;
  margin: auto;
  font-weight: bolder;
  &:after {
    font-size: 34px;
  }
}

.swiper-button-prev {
  @include pxToRem(left, 14);
}

.swiper-button-next {
  @include pxToRem(right, 14);
}

// todo: rebase 保留
//.full-size {
//  .swiper-slide {
//    .webp-image {
//      :deep(img) {
//        height: 100%;
//        object-fit: cover;
//        object-position: center;
//      }
//    }
//  }
//}
@include mediaMin($grid-breakpoints-md) {
  .swiper-button-prev:not(.swiper-button-hidden),
  .swiper-button-next:not(.swiper-button-hidden) {
    display: block;
  }
}
</style>

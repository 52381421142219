/**
 * 在 WebView 中使用 Google Analytics
 * @reference https://developers.google.com/analytics/devguides/collection/ga4/webview?hl=zh-cn&technology=ios
 * @param name Event Name
 * @param params Event Data
 */
export function logEvent(name, params) {
  if (!name) {
    return;
  }

  if (window.AnalyticsWebInterface) {
    // Call Android interface
    window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firebase) {
    // Call iOS interface
    const message = {
      command: 'logEvent',
      name,
      parameters: params,
    };
    window.webkit.messageHandlers.firebase.postMessage(message);
  }
  // No Android or iOS interface found
}

export function setUserProperty(name, value) {
  if (!name || !value) {
    return;
  }

  if (window.AnalyticsWebInterface) {
    // Call Android interface
    window.AnalyticsWebInterface.setUserProperty(name, value);
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firebase) {
    // Call iOS interface
    const message = {
      command: 'setUserProperty',
      name,
      value,
    };
    window.webkit.messageHandlers.firebase.postMessage(message);
  }
  // No Android or iOS interface found
}

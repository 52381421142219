import axios from 'axios';
import { isEmptyValue } from '@/helper/data-process';

const API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_PRODUCT_PRICE}`;

export default (id) => {
  if (isEmptyValue(id)) {
    return new Promise(function (resolve) {
      resolve({});
    });
  }

  return axios({
    method: 'get',
    url: API_PATH.replace(':productId', id),
  });
};

import XhrService from '@/services/api/xhr-service';
import { getHomePageAdSchema } from '@/schema/api/ad/home-page/home-page';

const service = new XhrService();

const API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_HOME_AD}`;

export default (search = '') =>
  service.fetchWithMultiSchema(
    {
      method: 'get',
      url: `${API_PATH}/${search}`,
    },
    getHomePageAdSchema(search),
    'banner_type',
  );

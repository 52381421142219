import * as yup from 'yup';
import { homeAdBaseSchema } from '@/schema/api/ad/home-page/common';

/**
 * [HomePage Ad] `hot_search` api response validate
 */
export const homePageHotSearch = yup.object().shape({
  id: yup.number().required(),
  // 基本 required 欄位: page, banner type, blank
  ...homeAdBaseSchema,
  image: yup.string().required(),
  alt: yup.string().required(),
  items: yup.array().of(
    yup.object().shape({
      title: yup.string().required(),
      url: yup.string().required(),
    }),
  ),
});

import { isNil, isEmpty, filter, forEach } from 'ramda';

export const isNull = (data) => data === null;

export const isEmptyString = (data) => data === '';

export const isStringNumber = (data) => !isNull(data.match(/^\d*$/));

export const isEmptyValue = (data) => isNil(data) || isEmpty(data);

export const emptyReplace = (value, defaultValue) => (isEmptyValue(value) ? defaultValue : value);

export const isFunction = (data) => typeof data === 'function';

export const rejectArrayNullItems = (data) => filter((any) => !isNil(any), data);

export const rejectEmpty = (data) => filter((any) => !isEmptyValue(any), data);

export const linkTarget = (blank) => (blank === true ? '_blank' : '_self');

export const monthsBeforeCurrent = (months) => new Date(new Date() - 1000 * 60 * 60 * 24 * 31 * months);

export const displayDate = (date, join = '/') => `${date.getFullYear()}${join}${date.getMonth() + 1}${join}${date.getDate()}`;

export const parseDecimalInt = (numberInString) => parseInt(numberInString, 10) || 0;

export function parseIntBetween({ input, min, max }) {
  const inputNumber = parseDecimalInt(input);
  if (inputNumber < min) return min;
  if (inputNumber > max) return max;
  return inputNumber;
}

export const randomInteger = (excludedMax, min = 0) => Math.floor(Math.random() * excludedMax + min);

export const isNumber = (num) => Number(num) === num;

/**
 * 型別判斷
 * @param {*} val 待確認得值
 * @returns {string} 型別字串(大駝峰)
 */
export const checkType = (val) => Object.prototype.toString.call(val).match(/^\[object\s(.*)\]$/)[1];

/**
 * get JSON with String
 * @param {string} jd JSON String
 * @return {object|null} json object or null
 */
export const getJSON = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

/**
 * 將指定資料(object)的 keys unset
 * @example removeKeys(response)(['banner_type']);
 */
export const objectRemoveKeys = (data) =>
  forEach((colKey) => {
    delete data[colKey];
  });

/**
 * 複製一個無關聯的 JSON
 * @param {object} data JSON 物件
 * @returns new JSON
 */
export const cloneJSON = (data) => JSON.parse(JSON.stringify(data));

/**
 * 依照 keys 拆分 object
 * @param {object} obj 要拆分的物件
 * @param {*} keys 須要拆分的 keys
 * @returns [object, object]
 */
export const splitObject = (obj, keys) => {
  return Object.entries(obj).reduce(
    ([obj1, obj2], [key, value]) => {
      return keys.includes(key) ? [{ ...obj1, [key]: value }, obj2] : [obj1, { ...obj2, [key]: value }];
    },
    [{}, {}],
  );
}

import * as yup from 'yup';
import '@/helper/yup/custom-methods';
import { homeAdBaseSchema, baseProductSchema } from '@/schema/api/ad/home-page/common';

/**
 * [HomePage Ad] `focus_point` api response validate
 */
export const homePageFocusPointSchema = yup.object().shape({
  id: yup.number().required(),
  // 基本 required 欄位: page, banner type, blank
  ...homeAdBaseSchema,
  products: yup.array().of(baseProductSchema),
});

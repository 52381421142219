<template>
  <img v-if="isOriginSourceWebp" :class="imgClass" :src="src" :alt="alt" />
  <img v-else ref="pictureRef" :class="imgClass" :src="imageSource" :name="`${alt}-webp-img`" :alt="alt" />
</template>

<script>
import { inject, nextTick, onMounted, ref, toRefs, computed } from 'vue';
import WebpPathConvertService, { isOriginSourceWebp } from '@/services/webp-path-convert-service';
import { productDefaultx3Img } from '@/constant/images-path';
import useCheckImageFile from "@/composables/utility/check-image-file";

const props = {
  src: {
    type: String,
    default: '',
  },
  alt: {
    type: String,
    default: '',
  },
  // 更新 `swiper` duplicate item 用，單純傳進參數即可。
  refreshFlag: {
    type: Number,
    default: 0,
  },
  // common style : 公用 style 再改這，母湯歐北加
  isCircle: {
    type: Boolean,
    default: false,
  },
  isCover: {
    type: Boolean,
    default: false,
  },
  isLazy: {
    type: Boolean,
    default: false,
  },
};

const setup = (props) => {
  const $isSupportWebp = inject('$isSupportWebp');
  const { src, isCircle, isCover, isLazy } = toRefs(props);
  const intersectionObservers = ref({});
  const isShow = ref(false);
  const loaded = ref(false);
  const defaultImage = ref(productDefaultx3Img);
  const pictureRef = ref(null);

  const { isImageError, checkImageFile } = useCheckImageFile();

  // 未加入圖片存在檢查的圖片位置
  const pureImageSource = computed(() => ($isSupportWebp ? WebpPathConvertService.convertPath(src.value) : src.value));
  const imageSource = computed(() => {
    if (isImageError.value === true) return defaultImage.value;
    return pureImageSource.value;
  });
  const imgClass = computed(() => ({ circle: isCircle.value, cover: isCover.value }));

  const setLazy = () => {
    // 沒開啟 Lazy load 功能
    if (isLazy.value !== true) return;
    intersectionObservers.value = new IntersectionObserver(async (entry, observer) => {
      const picture = entry[0];
      if (!picture) return;
      if (!picture.isIntersecting) return;
      isShow.value = true;
      observer.unobserve(picture.target);
    });
    intersectionObservers.value.observe(pictureRef.value);
  };

  const loadComplete = () => {
    loaded.value = true;
  };
  const created = async () => {
    // 檢查沒有 lazy + webp 的圖片
    await checkImageFile(pureImageSource.value);
    await nextTick();
    loadComplete();
  };

  onMounted(() => setLazy());

  created();

  return {
    pictureRef,
    imageSource,
    imgClass,
    isOriginSourceWebp,
    toWebp: WebpPathConvertService.convertPath,
  };
};

export default {
  name: 'WebpImage',
  props,
  setup,
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}
.circle {
  border-radius: 50%;
}
.cover {
  object-fit: cover;
}
</style>

<template>
  <div class="percentage-discount-price compare-percentage-price tw-flex tw-items-baseline">
    <div v-if="isShowDiscountLabel" class="discount-tag tw-px-1 md:tw-px-2 lg:tw-px-1">
      <span class="tag-text">
        <span class="discount bold">{{ discount }}</span>
        <span class="zhe bold">折</span>
      </span>
    </div>
    <div v-if="!isZero" class="price-num">
      <span class="bold cart-price-black"></span>
      <span class="bold num">{{ priceFormat(price) }}</span>
    </div>
    <div v-if="isShowPrePrice" class="pre-price-num">
      <span class="bold cart-price-black"></span>
      <span class="bold">{{ priceFormat(prePrice) }}</span>
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';
import priceFormat from '@/helper/filter/price-format';
import { isEmptyValue } from '@/helper/data-process';

const props = {
  showPrice: {
    type: Boolean,
    default: true,
  },
  guid: {
    type: String,
    default: '',
  },
  prePrice: {
    type: Number,
    default: null,
  },
  price: {
    type: Number,
    default: null,
  },
  discount: {
    type: String,
    default: '',
  },
  allowZero: {
    type: Boolean,
    default: false,
  },
  hideDiscountLabel: {
    type: Boolean,
    default: false,
  },
  hidePrePrice: {
    type: Boolean,
    default: false,
  },
};

export default {
  name: 'ComparePercentagePrice',
  props,
  setup(props) {
    const { allowZero, price, discount, hideDiscountLabel, hidePrePrice } = toRefs(props);

    const isZero = computed(() => {
      if (allowZero.value === true) return false;
      return price.value === 0;
    });
    const isShowDiscountLabel = computed(() => !isEmptyValue(discount.value) && !hideDiscountLabel.value);
    const isShowPrePrice = computed(() => discount.value && !hidePrePrice.value);

    return {
      isZero,
      isShowDiscountLabel,
      isShowPrePrice,
      priceFormat,
    };
  },
  computed,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/utility/product/part/hot-buy-percentage-price.scss';

@include mediaMax($grid-breakpoints-lg) {
  .discount-tag {
    border-radius: 0 0 0 0;
  }
}

@include mediaIs($grid-breakpoints-md) {
  $tag-height: 32;
  $text-height: 18;
  $text-margin-top: math.div(($tag-height - $text-height), 2) - 2;
  .discount-tag {
    @include pxToRem(height, $tag-height);
    @include pxToRem(max-height, $tag-height);
    box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.2);
    .discount {
      @include fontSize($text-height);
      line-height: 1;
    }
    .zhe {
      @include font14;
      margin-right: 0;
    }
    .tag-text {
      display: inline-block;
      @include pxToRem(margin-top, $text-margin-top);
    }
  }
}

#virtual-shelf-layout .eslite-home .compare-percentage-price {
  .pre-price-num {
    font-size: 1.25rem;
    .cart-price-black::before {
      font-size: 1.25rem;
    }
  }
  .discount-tag {
    $tag-height: 40;
    @include pxToRem(height, $tag-height);
    @include pxToRem(max-height, $tag-height);
    padding: 0 0.6rem !important;
    .discount {
      @include font24;
    }
    .zhe {
      @include font20;
    }
    .tag-text {
      margin-top: 0.1rem;
    }
  }
}
</style>

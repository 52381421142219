import axios from 'axios';

const API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_CART_MERGE}`;

export default (params, token) =>
  axios({
    method: 'post',
    headers: { Authorization: token },
    url: API_PATH,
    data: params,
  });

<template>
  <img v-lazy="pureImageSource" :class="imgClass" :name="imgName" :alt="alt" />
</template>

<script setup>
import { computed, inject } from 'vue';
import WebpPathConvertService, { isOriginSourceWebp } from '@/services/webp-path-convert-service';

const props = defineProps({
  src: {
    type: String,
    default: '',
  },
  alt: {
    type: String,
    default: '',
  },
  // 更新 `swiper` duplicate item 用，單純傳進參數即可。
  refreshFlag: {
    type: Number,
    default: 0,
  },
  // common style : 公用 style 再改這，母湯歐北加
  isCircle: {
    type: Boolean,
    default: false,
  },
  isCover: {
    type: Boolean,
    default: false,
  },
});

const { src, alt, refreshFlag, isCircle, isCover } = props;
const $isSupportWebp = inject('$isSupportWebp');

// 未加入圖片存在檢查的圖片位置
const pureImageSource = computed(() => {
  if (isOriginSourceWebp) return src;
  return $isSupportWebp ? WebpPathConvertService.convertPath(src) : src;
});
const imgClass = computed(() => {
  return { circle: isCircle, cover: isCover };
});
const imgName = computed(() => {
  if (isOriginSourceWebp) return '';
  return `${alt}-webp-lazy`;
});
</script>

<script>
export default {
  name: 'LazyWebpImage',
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/utility/image/base-image.scss';
</style>

import { split, pipe, map, zipObj, splitAt, mergeAll } from 'ramda';
import { isEmptyValue } from '@/helper/data-process';
import { esliteShareableQueryParamList } from '@/constant/site-query-string';
import { getUrlPart, composeUrlPart } from '@/helper/url/url-common';

export const toQueryString = (params) => new URLSearchParams(typeof params === 'string' ? JSON.parse(params) : params).toString();

export const toUrlParams = pipe(
  // grepUrlParams
  (urlSearch) => split('?', urlSearch),
  (x) => x[1] || x[0],
  // split &
  (queryString) => split('&', queryString),
  // split =
  map((x) => pipe(split('='), splitAt(1))(x)),
  map((x) => zipObj(...x)),
  // merge
  (x) => mergeAll(...x),
);

function appendQueryStringToPath({ link, queryStrings } = {}) {
  const origin = import.meta.env.VITE_BASE_URL;
  const fullLink = origin + link;
  const { pathname, searchParams, hash } = getUrlPart(fullLink);
  Object.keys(queryStrings).forEach((key) => {
    searchParams.set(key, queryStrings[key]);
  });
  return composeUrlPart({ pathname, searchParams, hash });
}

export function appendQueryString({ link, queryStrings } = {}) {
  if (!link || typeof link !== 'string') return '';
  if (isEmptyValue(queryStrings)) return link;
  if (link.indexOf('http') !== 0) {
    return appendQueryStringToPath({ link, queryStrings });
  }
  const { origin, pathname, searchParams, hash } = getUrlPart(link);
  Object.keys(queryStrings).forEach((key) => {
    searchParams.set(key, queryStrings[key]);
  });
  return composeUrlPart({ origin, pathname, searchParams, hash });
}

export function filterEsliteShareableSearchParams(searchParams) {
  const newSearchParams = new URLSearchParams();
  esliteShareableQueryParamList.forEach((queryKey) => {
    if (searchParams.has(queryKey)) {
      newSearchParams.set(queryKey, searchParams.get(queryKey));
    }
  });
  return newSearchParams;
}

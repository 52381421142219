import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { join, isNil, forEach, map, find, propEq, filter, isEmpty, or, all, prop, includes } from 'ramda';
import getHomeAds from '@/api/ad/home-page';
import { isFunction, rejectEmpty } from '@/helper/data-process';
import homePageAdFormatter from '@/helper/ad/home-page-formatter';
import { findFormatter } from '@/helper/ad/ad-helper';
import { homePageADKeyEnum } from '@/constant/ad/homepage-ad-type';

// getter: isNeedFetch
const getCurrentAd = (context) => map((types) => context[homePageADKeyEnum[types]], context.displayItems);
const hasNil = (context) => !all((x) => !isNil(x), getCurrentAd(context));
const hasEmpty = (context) => !all((x) => !isEmpty(x), getCurrentAd(context));
const isNeedFetch = (context) => or(hasEmpty(context), hasNil(context));

/**
 * actions
 */
// 整理資料部分
const getFormatter = (types) => homePageAdFormatter[findFormatter('homePage', types)];
const findAd = (types, data) => find(propEq('banner_type', types), data);
const filterExisted =
  (displayItems) =>
  ({ data }) =>
    filter((ad) => includes(prop('banner_type', ad), displayItems), data);
const getBannerType = (data) => map((ad) => prop('banner_type', ad), data);
const processAd = (data) => map((types) => (isFunction(getFormatter(types)) ? getFormatter(types)(findAd(types, data)) : {}), getBannerType(data));

export const defaultHomeAdState = {
  // 用來判斷是否重取
  timestamp: new Date(),
  // 品牌列表
  brandListing: {},
  // 流行熱搜
  hotSearch: {},
  // 會員 banner
  memberBanner: [],
  // 注目焦點
  focusPoint: {},
  // 大B
  bigBanner: [],
  // 今日最推
  groupRecommend: {},
  // 好康優惠大b
  discountBannerLarge: {},
  // 好康優惠小b
  discountBannerSmall: {},
  // 好康優惠專區
  discountArea: {},
  // 橫幅
  horizontalPaint: {},
  // 網路暢銷榜
  onlineLeaderboard: {},
  // 誠品獨家
  esliteExclusive: {},
  // 誠品選書
  esliteBook: {},
  // 誠品選樂
  esliteMusic: {},
  // 品牌週
  weeklyBrands: {},
  // 主題企劃
  mainTopicProject: {},
  // 線別
  threadGroups: {},
};

export const useHomeAdStore = defineStore('homeAd', {
  state: () => cloneDeep(defaultHomeAdState),
  getters: {
    getBrandListing: (context) => context.brandListing,
    getHotSearch: (context) => context.hotSearch,
    getMemberBanner: (context) => context.memberBanner,
    getFocusPoint: (context) => context.focusPoint,
    getBigBanner: (context) => context.bigBanner,
    getGroupRecommend: (context) => context.groupRecommend,
    getDiscountBannerLarge: (context) => context.discountBannerLarge,
    getDiscountBannerSmall: (context) => context.discountBannerSmall,
    getDiscountArea: (context) => context.discountArea,
    getHorizontalPaint: (context) => context.horizontalPaint,
    getOnlineLeaderboard: (context) => context.onlineLeaderboard,
    getEsliteExclusive: (context) => context.esliteExclusive,
    getEsliteBook: (context) => context.esliteBook,
    getEsliteMusic: (context) => context.esliteMusic,
    getWeeklyBrands: (context) => context.weeklyBrands,
    getMainTopicProject: (context) => context.mainTopicProject,
    getThreadGroups: (context) => context.threadGroups,
    getTimeStamp: (context) => context.timestamp,
    isNeedFetch,
  },
  actions: {
    // 取得資料部分
    fetchHomeAd(displayItems = []) {
      const adTypes = join(',', displayItems);
      const setAd = (data) =>
        forEach((ad) => {
          const stateName = homePageADKeyEnum[ad.type];
          this.$patch({
            [stateName]: ad,
          });
        }, data);
      const updateTime = () => (this.timestamp = new Date());

      return getHomeAds(adTypes)
        .then(filterExisted(displayItems))
        .then(processAd)
        .then(rejectEmpty)
        .then(setAd)
        .then(updateTime)
        .then(() => true)
        .catch((e) => e);
    },
  },
});

export const productVerEnum = {
  outOfPrint: '03',
  writeOff: '99',
};

export const productSellTypeEnum = {
  preOrder: 1, // 預購
  general: 0, // 預設
  customized: 2, // 客制
};

export const discountTypeEnum = {
  percentage: 'percentage',
  amount: 'amount',
};

export const leastBuyCountTypeEnum = {
  noCount: 'no_count',
  pieceCount: 'piece_count',
  groupCount: 'group_count',
};

import axios from 'axios';
import { isEmptyValue } from '@/helper/data-process';

const getUrl = (version) => {
  if (version === 2) return `${import.meta.env.VITE_API}${import.meta.env.VITE_API_SEARCH}`;
  return `${import.meta.env.VITE_HOLMES_HOST}${import.meta.env.VITE_API_SEARCH_V3}`;
};

const getRecommendUrl = (version) => {
  if (version === 2) return `${import.meta.env.VITE_API}${import.meta.env.VITE_API_SEARCH_KEYWORDS}`;
  return `${import.meta.env.VITE_HOLMES_HOST}${import.meta.env.VITE_API_SEARCH_V3_KEYWORDS}`;
};

const getAuthHeaders = (token) => {
  if (isEmptyValue(token) || token === 'false') return {};
  return {
    Authorization: token,
  };
};

const axiosOption = (version, token) => {
  const headers = getAuthHeaders(token);
  if (version === 3) {
    return {
      headers: { ...headers, 'content-type': 'application/x-www-form-urlencoded' },
    };
  }
  return {
    headers: { ...headers },
  };
};

export default (params, abortController, version = 2, token = undefined) =>
  axios({
    ...axiosOption(version, token),
    method: 'get',
    // 暫時保留：可以查到 `デザインアイディア & テクニック事典 01` 但會影響作者 `+Design`不能查
    // url: `${API_PATH}?${decodeURIComponent(qs.stringify(params))}`,

    // 一般可以查的串接方式，但無法查到 `デザインアイディア & テクニック事典 01`
    // url: `${API_PATH}?${toQueryString(params)}`,

    // url: `${import.meta.env.VITE_API}${import.meta.env.VITE_API_SEARCH}`,
    url: getUrl(version),
    params,
    signal: abortController.signal,
  });

export const fetchRecommendWords = (params, abortController, version = 2) =>
  axios({
    method: 'get',
    url: getRecommendUrl(version),
    params,
    signal: abortController.signal,
  });

import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

export default class CryptoUtility {
  constructor(secret) {
    this.secret = secret;
  }

  encrypt(plainText) {
    const encryptedData = AES.encrypt(plainText, this.secret);
    return encryptedData.toString();
  }

  decrypt(encryptedData) {
    const decryptedData = AES.decrypt(encryptedData, this.secret);
    return decryptedData.toString(Utf8);
  }
}

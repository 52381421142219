<template>
  <div class="product-item">
    <slot name="product-top"></slot>
    <div class="deep-base-product-card ec-card e-banner-product card-block">
      <router-link-usage :id="productGtmId" :link="product.url" :title="product.title || product.name">
        <div class="product-image-box">
          <slot name="product-image">
            <product-lazy-image :src="product.image" :is-adult="product.isAdult" :alt="product.title || product.name" :image-size="180"></product-lazy-image>
          </slot>
          <slot name="image-preorder-label">
            <div v-if="product.preorder" class="label">預購</div>
          </slot>
          <slot name="image-price-label"></slot>
        </div>
        <div class="text">
          <slot name="custom-part"></slot>
          <slot name="product-name">
            <div class="desc" :title="product.name">{{ product.name }}</div>
          </slot>
          <slot name="product-detail"></slot>
          <slot name="product-price">
            <percentage-discount-price class="price-block" :guid="product.id" :allow-zero="false" :type="priceStyle.compare"></percentage-discount-price>
          </slot>
        </div>
      </router-link-usage>
    </div>
  </div>
</template>

<script>
import ProductImage from '@/components/utility/product/product-image.vue';
import ProductLazyImage from '@/components/utility/product/image/product-lazy-image.vue';
import PercentageDiscountPrice from '@/components/utility/product/part/percentage-discount-price.vue';
import TextInBrackets from '@/components/utility/product/part/text-in-brackets.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import ALinkUsage from '@/components/utility/a-link-usage.vue';
import PriceLabel from '@/components/utility/product/price-label.vue';
import { bannerPriceType } from '@/constant/product/banner-price';
import { imagePath } from '@/helper/ad/ad-helper';

const components = {
  ProductImage,
  PercentageDiscountPrice,
  TextInBrackets,
  RouterLinkUsage,
  ALinkUsage,
  PriceLabel,
  ProductLazyImage,
};

const props = {
  // 刷新價格、component 元件的 flag (用於:key)
  refreshFlag: {
    type: Number,
    default: 0,
  },
  // 商品資料主體
  product: {
    type: Object,
    default: () => [],
  },
  productGtmId: {
    type: String,
    default: '',
  },
  isBackgroundTransparent: {
    type: Boolean,
    default: false,
  },
};
export default {
  name: 'BaseProduct',
  components,
  props,
  setup() {
    return {
      priceStyle: bannerPriceType,
      imagePath,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/utility/product/base-product.scss';
.e-banner-product {
  margin: 0 auto;
}
@include mediaMin($grid-breakpoints-lg) {
  .theme-product > .e-banner-product {
    @include pxToRem(width, 87.6);
  }
}
</style>

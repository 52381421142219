import { isEmpty, includes } from 'ramda';
import { isNil } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { memberPath, usePrivacyUserPage } from '@/constant/login-path';
import checkIsInlineApp from '@/helper/check-inline-app';
import { setCookie } from '@/helper/cookie';
import { useLoginStore } from '@/stores/login';

export default async function ({ redirect, route, pluginContext }) {
  const loginStore = useLoginStore();
  const { user, loggedIn } = storeToRefs(loginStore);
  // 判斷 jwt
  // 需要串接的頁面在該頁需要再做 isLogin 判斷, 如果 token 是錯誤的還需導回 /login
  const { jwttoken, rec_trade_id: recTradeId } = route.query;
  if (jwttoken) {
    await pluginContext.$auths.setToken(jwttoken);
    await pluginContext.$auths.verifyTokenAndInitUser().then(() => redirect(route.path));
  }

  // android 街口支付 deep link step3 轉跳
  const notInlineApp = !checkIsInlineApp();
  const isJKOPay = recTradeId?.substr(0, 2) === 'JK' || false;
  const isLINEPay = recTradeId?.substr(0, 2) === 'LN' || false;
  const isThirdPartyPayment = isJKOPay || isLINEPay;
  const isAndroid = navigator.userAgent.match(/android/i) !== null;
  const isStep3Page = route.name === 'cart-step3';
  const basePath = import.meta.env.VITE_BASE_URL.replace('https://', '');
  const appPath = `eslitemember://${basePath}${route.fullPath}`;
  // 判斷若為第三方支付且為 android 網頁瀏覽 cart step3 畫面時
  if (isThirdPartyPayment && notInlineApp && isAndroid && isStep3Page) {
    try {
      window.location.href = appPath;
    } catch (error) {
      console.log(error);
    }
    // return;
  }

  // 已登入狀態判斷是否要移除隱碼個資
  if (includes(route.name, usePrivacyUserPage)) {
    // const checkPrivUser = !!(store.getters['login/privacyUser']?.name || false);
    // if (!checkPrivUser) { console.log(`缺資料: 呼叫 api`); }
  } else {
    await loginStore.setPrivacyUser(null);
  }

  // 只檢查需要登入的頁面
  if (!includes(route.path, memberPath)) return;

  // route query 為 logout=refresh, 不需存 cookie, 只需導回 login
  if (!isNil(route.query.logout) && route.query.logout === 'refresh') {
    redirect('/login');
    return;
  }

  const isLogin = loggedIn.value && !isEmpty(user.value);
  // console.log({ user, loggedIn, isLogin });

  // 沒登入並且 path 也沒帶任何參數時，導回 login，不紀錄 cookie
  // 使用者在登入頁按回上一頁時，防止未登入的情況下回到需登入且沒帶參數的頁面
  if (!isLogin && isEmpty(route.query)) {
    redirect('/login');
    return;
  }

  // Related issue #1431
  if (!isLogin) {
    setCookie('original-path', route.fullPath, 1);
    redirect('/login');
  }
}

<template>
  <div class="price-label discount-tag px-1" v-if="discountPriceOnly">
    <span>
      <span class="discount bold">{{ discountPriceOnly }}</span>
      <span class="zhe">折</span>
    </span>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import productPrice from '@/composables/product/product-price';

const props = {
  guid: {
    type: String,
    default: '',
  },
};

const setup = (props) => {
  const { guid } = toRefs(props);
  const { discountPriceOnly } = productPrice({ guid: guid.value });

  return {
    /**
     * Composable
     */
    discountPriceOnly,
  };
};

export default {
  name: 'PriceLabel',
  props,
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/utility/product/part/hot-buy-percentage-price.scss';
.discount {
  @include fontSize(14);
}
.zhe {
  //@include font12;
  @include fontSize(12);
}
.price-label {
  @include pxToRem(height, 24);
  @include pxToRem(max-height, 24);
  span {
    @include pxToRem(line-height, 24);
  }
}
</style>

import { join, map, mapObjIndexed } from 'ramda';
import fetchProductPrice from '@/api/product/product-price';
import { formatRuleTypeEnum } from '@/constant/product/product-price';
import { leastBuyCountTypeEnum } from '@/constant/product';
import { formatDiscountLevel, formatDiscountRange } from '@/helper/filter/discount';

const priceFormatter = {
  [formatRuleTypeEnum.wording]: formatDiscountLevel,
  [formatRuleTypeEnum.priceOnly]: formatDiscountRange,
};

const formatterParamsGetter = {
  [formatRuleTypeEnum.wording]: (data) => ({ type: data?.type, range: data?.range }),
  [formatRuleTypeEnum.priceOnly]: (data) => data?.range,
};

const formatDsicount = (product, formatterType) => priceFormatter[formatterType](formatterParamsGetter[formatterType](product));
const formatDsicountForAllTypes = (product) => mapObjIndexed((formatterType) => formatDsicount(product, formatterType), formatRuleTypeEnum);
const format = ({ data }) =>
  map(
    (product) => ({
      id: product.product_guid || product.guid,
      status: product?.product_button_status,
      originPrice: product?.final_price,
      price: product?.retail_price,
      type: product?.type || '',
      range: product?.range,
      discountsOfTypes: formatDsicountForAllTypes(product),
      leastBuyCountType: product?.least_buy_count_type || leastBuyCountTypeEnum.groupCount,
      leastBuyCountValue: product.least_buy_count_value !== undefined ? product.least_buy_count_value : 1,
    }),
    data,
  );

const getPrices = async (products) => {
  // 將 id 變成 `idA, idB, idC` 格式後，呼叫 api
  const params = join(',', products);
  if (!params) return Promise.resolve({});
  return fetchProductPrice(params).then((response) => format(response));
};

export default {
  getPrices,
};

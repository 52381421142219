/**
 * promise delay callback
 * @param {*} res any response data
 * @param {number} timer delay time
 * @returns Promise
 */
export const delay = function (res, timer = 300) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(res);
    }, timer);
  });
};

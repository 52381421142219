export const yesNoEnum = {
  yes: 'yes',
  no: 'no',
};

export const SuccessFailEnum = {
  Wishsuccess: 'Wishsuccess',
  success: 'success',
  fail: 'fail',
  error: 'error', // 500 之類的預設錯誤
  none: '',
};

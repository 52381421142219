<template>
  <div class="text-in-brackets" :class="{ 'is-single-line': isSingleLine }" :title="text">
    <div class="inner-text">{{ text }}</div>
  </div>
</template>

<script>
const props = {
  text: {
    type: String,
    default: '',
  },
  isSingleLine: {
    type: Boolean,
    default: false,
  },
};

export default {
  name: 'TextInBrackets',
  props,
};
</script>

<style lang="scss" scoped>
.text-in-brackets {
  text-align: center;
  letter-spacing: normal;
  color: var(--military-green);
  &::before,
  &::after {
    display: inline;
    color: var(--brown-grey);
  }
  &::before {
    content: '【 ';
  }
  &::after {
    content: ' 】';
  }
}

.inner-text {
  display: inline;
  letter-spacing: normal;
}

.text-in-brackets:not(.is-single-line) {
  -webkit-line-clamp: 2;
  height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-in-brackets.is-single-line {
  display: flex;
  justify-content: center;
  &::before,
  &::after {
    flex: 0 0 auto;
  }
  &::before {
    margin-right: 0.5rem;
  }
  &::after {
    margin-left: 0.5rem;
  }
  .inner-text {
    flex: 0 1 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>

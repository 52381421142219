/* eslint-disable camelcase */
import { defineStore } from 'pinia';
import cryptoUtility from '@/helper/crypto.js';
import { isEmptyValue } from '@/helper/data-process';

// const defaultMemberKeys = ['id', 'uuid', 'is_adult', 'lms_member_id'];
const otherMemberKeys = [
  'card_code',
  'card_level',
  'card_name',
  'country',
  'day_tel',
  'day_tel_area',
  'day_tel_ext',
  'phone_country_code',
  'phone_status',
  'promotion_card_level',
  'promotion_card_name',
  'sex',
  'zipcode',
];

// Create a new store instance.
const state = () => ({
  loggedIn: false,
  strategy: 'local',
  user: null,
  privacyPersonalInformation: null,
});

const getters = {
  privacyUser(state) {
    return state.privacyPersonalInformation === null
      ? state.privacyPersonalInformation
      : {
          account: state.privacyPersonalInformation?.account || '',
          address: state.privacyPersonalInformation?.address || '',
          birthday: state.privacyPersonalInformation?.birthday || '',
          city_district: state.privacyPersonalInformation?.city_district || '',
          email: state.privacyPersonalInformation?.email || '',
          name: state.privacyPersonalInformation?.name || '',
          phone_number: state.privacyPersonalInformation?.phone_number || '',
        };
  },
  getUser(state) {
    return state.user || null;
  },
};

const actions = {
  changeLogin(type) {
    this.loggedIn = type;
    type = null;
  },
  setUser(user) {
    if (user === null) {
      this.user = null;
      return;
    }
    const { id, uuid, is_adult, lms_member_id } = user;
    this.user = { id, uuid, is_adult, lms_member_id };
    user = null;
  },
  setPrivacyUser(user) {
    if (user === null) {
      if (this.user?.id !== undefined) {
        otherMemberKeys.forEach((x) => delete this.user[x]);
      }
      this.privacyPersonalInformation = null;
    } else {
      otherMemberKeys.forEach((x) => {
        if (user[x] !== undefined) this.user[x] = user[x];
      });

      this.privacyPersonalInformation = {
        account: isEmptyValue(user.account) ? '' : cryptoUtility.encrypt(user.account),
        address: isEmptyValue(user.address) ? '' : cryptoUtility.encrypt(user.address),
        birthday: isEmptyValue(user.birthday) ? '' : cryptoUtility.encrypt(user.birthday),
        city_district: isEmptyValue(user.city_district) ? '' : cryptoUtility.encrypt(user.city_district),
        email: isEmptyValue(user.email) ? '' : cryptoUtility.encrypt(user.email),
        name: isEmptyValue(user.name) ? '' : cryptoUtility.encrypt(user.name),
        phone_number: isEmptyValue(user.phone_number) ? '' : cryptoUtility.encrypt(user.phone_number),
      };
    }
    user = null;
  },
};

export const useLoginStore = defineStore('login', {
  state,
  getters,
  actions,
});

import { mergeAll, map, zipObj } from 'ramda';

/**
 * @const {array} brandEnum brand enum
 */
export const brandLevel3AD = [
  {
    description: '大Banner',
    code: 'brand_l3_big_b',
    key: 'bigBanner',
    mappingName: 'BigBanner',
  },
];

/**
 * 品牌頁廣告「類型」
 * {object} brandLevel3ADTypeEnum { key: code } 組合
 * @sample bigBanner: 'brand_l2_big_b'
 * =============================================
 * */
export const brandLevel3ADTypeEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.key], [x.code]);
    }, brandLevel3AD),
  );
})();

/**
 * 品牌頁廣告「Mapping」
 *   用來對應 method name
 * {object} brandLevel3ADTypeEnum { code: mappingName } 組合
 * @sample BigBanner: 'brand_l2_big_b'
 * =============================================
 * */
export const brandLevel3ADMappingEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.code], [x.mappingName]);
    }, brandLevel3AD),
  );
})();

/**
 * 品牌頁廣告「Mapping」
 *   用來對應 method name
 * {object} brandLevel3ADTypeEnum { code: key } 組合
 * @sample 'brand_l2_big_b': bigBanner
 * */
export const brandLevel3ADKeyEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.code], [x.key]);
    }, brandLevel3AD),
  );
})();

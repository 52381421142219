/**
 * 預設空節點
 * @type {{path: string, depth: number, id: number, level1: null, level3: null, level3Description: string, level2: null, level1Description: string, level2Description: string}}
 */
export const defaultCategoryNode = {
  depth: 0,
  id: 0,
  level1: null,
  level2: null,
  level3: null,
  level1Description: '',
  level2Description: '',
  level3Description: '',
};

/**
 * sidebar 展開 class
 * @type {{active: string, open: string}}
 */
export const activeClass = {
  active: 'active',
  open: 'open',
};

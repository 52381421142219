import { emptyReplace } from '@/helper/data-process';
import { formatImagePath } from '@/helper/image-formatter';
import { formatDiscountRange } from '@/helper/filter/discount';
import { yesNoEnum } from '@/constant/common/enums';

function isSameDomain(link) {
  return link.indexOf(import.meta.env.VITE_BASE_URL) === 0;
}

function formatProductLink({ guid, link, useDefaultLink }) {
  const defaultUrl = `/product/${guid}`;
  if (!link || useDefaultLink) return defaultUrl;
  // 站外連結
  if (!isSameDomain(link)) return link;
  // 站內連結
  return link.replace(import.meta.env.VITE_BASE_URL, '');
}

export const productFormatterBase = (product, useDefaultLink = false) => {
  const productImage = emptyReplace(product.image, '');
  return {
    id: emptyReplace(product.product_guid, ''),
    image: formatImagePath(productImage),
    name: emptyReplace(product.name, ''),
    title: emptyReplace(product.title, ''),
    alt: emptyReplace(product.name, ''),
    origin: product.final_price,
    price: product.retail_price,
    url: formatProductLink({ guid: product.product_guid, link: product.product_link, useDefaultLink }),
    discount: formatDiscountRange(product.range),
    isAdult: product.restricted === yesNoEnum.yes || product.restricted === true,
  };
};

export const extraFieldEnum = {
  author: 'author',
  detail: 'detail',
  preorder: 'preorder',
};

export const extraFieldFormatter = {
  [extraFieldEnum.author]: (product) => emptyReplace(product.author, ''),
  [extraFieldEnum.detail]: (product) => emptyReplace(product.short_description, ''),
  [extraFieldEnum.preorder]: (product) => emptyReplace(product.preorder, false),
};

export const productFormatterMaker = ({ extraFields, useDefaultLink = false }) => (product) => {
  const formatted = productFormatterBase(product, useDefaultLink);
  extraFields.forEach((key) => (formatted[key] = extraFieldFormatter[key](product)));
  return formatted;
};

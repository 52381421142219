import * as yup from 'yup';
import '@/helper/yup/custom-methods';
import { homeAdBaseSchema, baseProductSchema } from '@/schema/api/ad/home-page/common';

/**
 * [HomePage Ad] `home_page_main_topic_project` api response validate
 */
export const homePageMainTopicProjectSchema = yup.object().shape({
  // 基本 required 欄位: page, banner type, blank
  ...homeAdBaseSchema,
  items: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      title: yup.string().required(),
      image: yup.string().required(),
      link: yup.string().required(),
      sort: yup.number().required(),
      products: yup.array().of(baseProductSchema),
    }),
  ),
});

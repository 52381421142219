import * as yup from 'yup';
import { forEach, forEachObjIndexed, length, propEq, filter, pipe, values } from 'ramda';
import { baseAdItemsWithTitleSchema, baseProductSchema } from '@/schema/api/ad/home-page/common';
import { objectRemoveKeys } from '@/helper/data-process';
import { checkDataExists, checkDataTypes } from '@/helper/validate-helper';

/**
 * 線別商品 schema
 */
const threadProducts = yup.object().shape({
  id: yup.number().required(),
  blank: yup.boolean().required(),
  banner_type: yup.string().required(),
  products: yup.array().of(baseProductSchema),
});

/**
 * 線別Banners schema
 */
const threadBanners = yup.object().shape({
  blank: yup.boolean().required(),
  banner_type: yup.string().required(),
  items: yup.array().of(baseAdItemsWithTitleSchema),
});

/**
 * 線別 keywords schema
 */
// todo: 憲哥説等靈光乍現
// const keywordSchema = yup.object().shape({
//   link: yup.string().required(),
//   text: yup.string().required(),
// });
// const threadKeywordWrapper = yup.object().shape({
//   id: yup.number().required(),
//   blank: yup.boolean().required(),
//   banner_type: yup.string().required(),
// });
// todo: 先用土法煉鋼自己刻
const wrapperObjectSchema = {
  id: 'number',
  blank: 'boolean',
  banner_type: 'string',
  keywords: 'object',
};
const keywordsItemSchema = {
  link: 'string',
  text: 'string',
};

const validateKeywords = (data) => {
  // 基本
  checkDataExists(data)(['id', 'blank', 'banner_type', 'keywords']);
  checkDataTypes(data)(wrapperObjectSchema);
  // keywords
  forEach(async (item) => {
    await checkDataExists(item)(['link', 'text']);
    await checkDataTypes(item)(keywordsItemSchema);
  })(values(data.keywords));
};
const getProps = (type) => pipe(filter(propEq('banner_type', type)), (x) => x[0]);

// 用一個 method 保持 `yup` interface 一致
export const threadGroupValidateSync = async (data) => {
  // 移除 key，不影響 reference 先解構一次。
  const response = { ...data };
  const type = data.banner_type;
  objectRemoveKeys(response)(['banner_type']);
  // validate error
  const errorSummary = [];
  await forEachObjIndexed(async (value, key) => {
    try {
      // 一種類別裡面有三種版位
      const banners = getProps('thread_little_b')(value);
      const keywords = getProps('thread_keywords')(value);
      const products = getProps('thread_product')(value);
      if (!banners || !keywords || !products) throw new Error('缺少版位（banner or keywords or products）資料。');

      // 分別檢驗 schema
      await threadProducts.validateSync(products);
      await threadBanners.validateSync(banners);
      // keywords 用套件測不到，自己寫
      validateKeywords(keywords);
    } catch (error) {
      error.message = `banner[${type}] : errorKey = ${key}, errorMessage = ${error.message}`;
      errorSummary.push(error);
    }
  }, response);
  // 統整錯誤並拋出
  if (length(errorSummary) > 0) throw new Error(errorSummary);
};

export const homePageThreadGroupSchema = {
  validateSync: threadGroupValidateSync,
};

<template>
  <div class="percentage-discount-price hot-buy-percentage-price d-flex align-items-baseline">
    <div v-if="isShowDiscountLabel" class="discount-tag px-1 px-md-2 px-lg-1">
      <span class="tag-text">
        <span class="discount bold">{{ discount }}</span>
        <span class="zhe">折</span>
      </span>
    </div>
    <div v-if="!isZero" class="price-num">
      <span class="bold cart-price-black"></span>
      <span class="bold num">{{ priceFormat(price) }}</span>
    </div>
    <div v-if="discount" class="pre-price-num">
      <span class="bold cart-price-black"></span>
      <span class="bold">{{ priceFormat(prePrice) }}</span>
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';
import priceFormat from '@/helper/filter/price-format';
import { isEmptyValue } from '@/helper/data-process';

const props = {
  showPrice: {
    type: Boolean,
    default: true,
  },
  guid: {
    type: String,
    default: '',
  },
  prePrice: {
    type: Number,
    default: null,
  },
  price: {
    type: Number,
    default: null,
  },
  discount: {
    type: String,
    default: '',
  },
  allowZero: {
    type: Boolean,
    default: false,
  },
  hideDiscountLabel: {
    type: Boolean,
    default: false,
  },
};

export default {
  name: 'LeftboxPercentagePrice',
  props,
  setup(props) {
    const { allowZero, price, discount, hideDiscountLabel } = toRefs(props);

    const isZero = computed(() => {
      if (allowZero.value === true) return false;
      return price.value === 0;
    });
    const isShowDiscountLabel = computed(() => !isEmptyValue(discount.value) && !hideDiscountLabel.value);

    return {
      isZero,
      isShowDiscountLabel,
      priceFormat,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/utility/product/part/hot-buy-percentage-price.scss';

@include mediaIs($grid-breakpoints-md) {
  $tag-height: 40;
  $text-height: 28;
  $text-margin-top: math.div(($tag-height - $text-height), 2) - 2;
  .discount-tag {
    @include pxToRem(height, $tag-height);
    @include pxToRem(max-height, $tag-height);
    box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.2);
    .discount {
      @include fontSize($text-height);
      line-height: 1;
    }
    .zhe {
      @include font20;
      margin-right: 0;
    }
    .tag-text {
      display: inline-block;
      @include pxToRem(margin-top, $text-margin-top);
    }
  }
}
</style>

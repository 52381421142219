import { keys, reduceBy, includes, forEachObjIndexed, forEach } from 'ramda';

export const isProduction = () => import.meta.env.VITE_TYPE === 'prd';

export const checkColumnsKeys = (schema, compareTarget, apiPath) => {
  // for 開發提醒
  if (!apiPath) throw new Error('checkColumnsKeys() 請填 apiPath，才不會找不到錯誤。');

  // 整理資料，只留 key
  const validateColumns = keys(schema.fields);
  const target = keys(compareTarget);
  // reduce 分組 {pass: mapping ok欄位, notify: 多出的欄位}
  const groups = (access, value) => access.concat(value);
  const toGroups = (value) => (includes(value, validateColumns) ? 'pass' : 'notify');
  const result = reduceBy(groups, [], toGroups, target);
  // 多出欄位，不影響程式執行，production 不報錯
  if (isProduction()) return;
  if (result.notify) throw new Error(`有多的 response 欄位未處理，多出欄位${JSON.stringify(result.notify)}，from: ${apiPath}。`);
};

export const checkDataTypes = (data) => (schema) => {
  forEachObjIndexed(async (value, key) => {
    if (value !== typeof data[key]) throw new Error(`data '${key}' 型態不為 ${value}，資料為'${data[key]}'`);
  })(schema);
};

export const checkDataExists = (data) => (columns) => {
  forEach(async (value) => {
    if (data[value] === '' || data[value] === false) return;
    if (!data[value]) throw new Error(`data '${value}' 不存在，實際值為'${data[value]}', [data]: ${JSON.stringify(data)})`);
  })(columns);
};

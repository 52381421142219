import * as yup from 'yup';
import { baseProductSchema, homeAdBaseSchema } from '@/schema/api/ad/home-page/common';

/**
 * [HomePage Ad] `music_of_eslite_choice` api response validate
 */
export const homePageEsliteMusicSchema = yup.object().shape({
  id: yup.number().required(),
  // 基本 required 欄位: page, banner type, blank
  ...homeAdBaseSchema,
  products: yup.array().of(baseProductSchema),
});

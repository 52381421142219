import { siteQueryKeys, layoutQueryEnum } from '@/constant/site-query-string';

export const virtualShelfRouteNames = {
  index: true,
  'category-1-id': true,
  'exhibitions-id': true,
  'product-id': true,
  brand: true,
  'brand-id': true,
  'tags-tags': true,
};

export const virtualShelfNotSupportHashAtHome = '#virtual-shelf-not-support-a-link-at-home';
export const virtualShelfNotSupportHash = '#virtual-shelf-not-support-a-link';
export const virtualShelfNotSupportHomeLink = `${import.meta.env.VITE_BASE_URL}?${siteQueryKeys.layout}=${layoutQueryEnum.virtualShelf}${virtualShelfNotSupportHashAtHome}`;

export const virtualShelfAlertRedirectStatus = {
  // 站內 不支援虛擬貨架 之頁面，顯示alert後，需返回前頁
  default: 'default',
  // @click 檢查為 不支援虛擬貨架 之外部網站，不需導過去，原地顯示alert後，不需返回前頁
  isEarlyRedirectBack: 'isEarlyRedirectBack',
  // 不支援虛擬貨架 之 hash tag，顯示alert後，需返回未加hash之原頁
  isFromNotSupportHash: 'isFromNotSupportHash',
};

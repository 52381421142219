import { defineStore } from 'pinia';
import { forEach, includes, keys, filter } from 'ramda';
import productPriceService from '@/services/product/product-price-service';
import { isEmptyValue } from '@/helper/data-process';

/**
 * State
 * @return {{prices: {}, isComplete: boolean}}
 */
const state = () => ({
  prePrices: {},
  prices: {},
  discountsOfTypes: {},
  isComplete: {},
  leastBuyStatus: {},
  productButtonStatus: {},
});

/**
 * Getters
 */
const getPrePrices = (state) => state.prePrices;
const getPrices = (state) => state.prices;
const getDiscountsOfTypes = (state) => state.discountsOfTypes;
const getLeastBuyStatus = (state) => state.leastBuyStatus;
const getProductButtonStatus = (state) => state.productButtonStatus;

const getters = {
  getPrePrices,
  getPrices,
  getDiscountsOfTypes,
  getLeastBuyStatus,
  getProductButtonStatus,
};

/**
 * Actions
 */
// reject already fetch
const rejectAlreadyFetch = (prices, products) => filter((product) => !includes(product, keys(prices)))(products);

// loading complete status
function setPriceCompleteStatus({ context, status }) {
  return forEach((productId) => {
    context.isComplete[productId] = status;
  });
}

// set Price and Discount
function setPriceAndDiscount(context) {
  return forEach((product) => {
    context.leastBuyStatus[product.id] = {
      leastBuyCountType: product.leastBuyCountType,
      leastBuyCountValue: product.leastBuyCountValue,
      status: product.status,
      guid: `${product.id}`,
    };
    context.prePrices[product.id] = product.originPrice;
    context.prices[product.id] = product.price;
    context.discountsOfTypes[product.id] = product.discountsOfTypes;
    context.productButtonStatus[product.id] = product.status;
  });
}

export const actions = {
  // get price from api
  fetchPrice({ productIds }) {
    // remove already fetch item
    const filteredProductIds = rejectAlreadyFetch(this.prices, productIds);

    // reject empty
    if (isEmptyValue(filteredProductIds)) return;

    // set loading status
    setPriceCompleteStatus({ context: this, status: false })(filteredProductIds);

    // eslint-disable-next-line consistent-return
    return productPriceService
      .getPrices(filteredProductIds)
      .then(setPriceAndDiscount(this))
      .then(() => {
        setPriceCompleteStatus({ context: this, status: true })(filteredProductIds);
      });
  },
  updateProductButtonStatus({ productId, status }) {
    this.productButtonStatus[productId] = status;
  },
};

export const usePriceStore = defineStore('price', {
  state,
  getters,
  actions,
});

<template>
  <div class="product-image e-square" :title="alt" @click="$emit('click')" @mouseover="$emit('mouseover')">
    <!-- 正常-->
    <div v-if="!restricted" class="item-image-wrap e-aspect-ratio-inner" :title="alt">
      <slot name="cover">
        <div class="gray-cover"></div>
      </slot>
      <lazy-webp-image class="product webp-image" :src="src" :alt="alt"></lazy-webp-image>
    </div>
    <!-- 成人標示-->
    <div v-else class="item-image-wrap e-aspect-ratio-inner tw-bg-gray-f4">
      <slot name="image-top"></slot>
      <image-wait-loaded :is-skeleton-image="isSkeletonImage"><img src="@/static/images/adult-only.webp" /></image-wait-loaded>
    </div>
  </div>
</template>

<script>
import { toRef } from 'vue';
import ImageWaitLoaded from '@/components/utility/image/image-wait-loaded.vue';
import LazyWebpImage from '@/components/utility/image/lazy-webp-image.vue';
import { useProductImageRestrict } from '@/composables/product/product-image-restrict';

const props = {
  alt: {
    type: String,
    default: '',
  },
  src: {
    type: String,
    default: '',
  },
  isAdult: {
    type: Boolean,
    default: false,
  },
  disableLimit: {
    type: Boolean,
    default: false,
  },
  isSkeletonImage: {
    type: Boolean,
    default: false,
  },
};

const setup = (props) => {
  const disableLimit = toRef(() => props.disableLimit);
  const isAdult = toRef(() => props.isAdult);
  const { restricted } = useProductImageRestrict({ disableLimit, isAdult });

  return {
    restricted,
  };
};

export default {
  name: 'ProductLazyImage',
  components: { ImageWaitLoaded, LazyWebpImage },
  props,
  setup,
};
</script>

<style lang="scss" scoped>
.product-image,
.item-image-wrap {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
}
.product-image {
  position: relative;
}
.gray-cover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.03);
  pointer-events: none;
}
.item-image-wrap {
  // for is adult
  img {
    width: 100%;
    height: 100%;
    // 不設 max-height，交由 object-fit contain、cover 決定
    object-fit: contain;
    object-position: center;
  }
}
</style>

import { defineStore } from 'pinia';
import PriceQueueService from '@/services/price-queue-service';
import { usePriceStore } from '@/stores/price';
import { checkIsTest } from '@/helper/test/test-env';

let priceQueue = null;

const actions = {
  getOrInitPriceQueue() {
    if (priceQueue && !checkIsTest()) return priceQueue;
    // 尚未初始，建立queue
    const priceStore = usePriceStore();
    const fetchPrice = (productIds) => priceStore.fetchPrice({ productIds });
    priceQueue = new PriceQueueService(fetchPrice);
    return priceQueue;
  },
  async fetchPriceByQueue({ productIds }) {
    const queue = await this.getOrInitPriceQueue();
    const priceStore = usePriceStore();
    const unfetchedIds = productIds.filter((id) => !priceStore.getPrices[id]);
    if (unfetchedIds.length === 0) return;
    queue.pushProductIds(unfetchedIds);
  },
};

export const usePriceQueueStore = defineStore('priceQueue', {
  actions,
});

<template>
  <div class="recommend-swiper-mobile">
    <recommend-tabs class="slides-tabs" :data="groupTitles" :value="currentGroupIndex" @select="select"></recommend-tabs>
    <div class="slides-wrapper">
      <div v-for="(subProducts, index) in groupProducts" :key="`recommend-slide-${index}`" class="slide">
        <!--   key 加 product.id，避免切換頁籤，沒抓price-->
        <product
          v-for="(product, subIndex) in subProducts"
          :key="`recommend-product-${index}-${subIndex}-${product.id}`"
          class="slide-product"
          :product="product"
          :show-golden-title="true"
          :show-divider="true"
          :product-gtm-id="`${sourceID}${currentGroupIndex + 1}-${product.itemIndex}`"
          :use-product-image="true"
          :is-lazy="false"
        ></product>
      </div>
    </div>
  </div>
</template>

<script>
import { concat, map, splitEvery, transpose, repeat } from 'ramda';
import { ref, computed } from 'vue';
import { useGetHomeAdStore } from '@/composables/store/home-ad';
import Product from '@/components/utility/product/banner-product.vue';
import RecommendTabs from '@/components/index/recommend-and-hot-topic-position/recommend-tabs.vue';

const props = {
  sourceID: String,
};

const formatProductGroup = (recommends) => {
  const groups = recommends.map((group) => group.products.map((product, subIndex) => ({ ...product, groupIndex: group.groupId, itemIndex: subIndex + 1 })));
  const groupsOfTwoRowProducts = map((group) => transpose(splitEvery(4, group)), groups);

  if (groupsOfTwoRowProducts.length < 2) return groupsOfTwoRowProducts[0];
  return groupsOfTwoRowProducts.reduce((result, group) => {
    return concat(result, group);
  }, []);
};

const setup = () => {
  const { groupRecommend } = useGetHomeAdStore();

  const currentGroupIndex = ref(0);

  const hasDailyRecommend = computed(() => {
    // undefine
    if (!groupRecommend.value?.group) return false;
    // type && length
    return groupRecommend.value.group.length >= 1;
  });

  const groupProducts = computed(() => {
    if (!hasDailyRecommend.value) return repeat(repeat({}, 2), 4);
    const grepGroup = [groupRecommend.value.group[currentGroupIndex.value]];
    return formatProductGroup(grepGroup);
  });

  const groupTitles = computed(() => {
    return map((group) => group?.groupTitle, groupRecommend.value.group || []);
  });

  const select = (index) => {
    currentGroupIndex.value = index;
  };

  return {
    currentGroupIndex,
    groupProducts,
    groupTitles,
    select,
  };
};

export default {
  name: 'RecommendSwiperMobileV2',
  components: { Product, RecommendTabs },
  props,
  setup,
};
</script>

<style lang="scss" scoped>
.recommend-swiper-mobile {
  :deep(.deep-product-card) {
    padding: 0 1rem 1rem 0;
  }
}
.slides-tabs,
.slides-wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.slides-wrapper {
  white-space: nowrap;
}
.slide {
  width: 40vw;
  display: inline-block;
}
#virtual-shelf-layout .recommend-swiper-mobile {
  .slide {
    width: 37vw;
    .slide-product:first-of-type {
      border-bottom: solid 1px var(--m-gray-cf);
      margin-bottom: $virtual-shelf-spacer;
    }
  }
  :deep(.all-group-title) {
    .title:first-of-type {
      margin-left: 0;
    }
    .title {
      @include fontSize(24);
      .tab {
        @include pxToRem(width, 260);
        @include pxToRem(max-width, 260);
      }
      .tab-content {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
@include mediaIs($grid-breakpoints-sm) {
  .slide {
    width: calc(0.3 * #{$container-width-sm});
  }
}
@include mediaIs($grid-breakpoints-md) {
  .slide {
    width: calc(0.28 * #{$container-width-md});
  }
}
</style>

import { mergeAll, map, zipObj } from 'ramda';

/**
 * @const {array} categoryADType invoice enum
 */
export const categoryADType = [
  {
    description: '大Banner',
    code: 'big_b',
    key: 'bigBanner',
    mappingName: 'BigBanner',
  },
  {
    description: '今日最推薦',
    code: 'recommend_of_the_day',
    key: 'dailyRecommend',
    mappingName: 'DailyRecommend',
  },
  {
    description: '新品推薦',
    code: 'recommend_new_products',
    key: 'newProducts',
    mappingName: 'NewProducts',
  },
  {
    description: '編輯推薦',
    code: 'editor_recommend',
    key: 'editorRecommend',
    mappingName: 'EditorRecommend',
  },
  {
    description: '主打選品',
    code: 'main_prompt_products',
    key: 'mainPromptProducts',
    mappingName: 'MainPromptProducts',
  },
  {
    description: '熱門話題',
    code: 'hot_topic',
    key: 'hotTopic',
    mappingName: 'HotTopic',
  },
  {
    description: '熱門話題小b',
    code: 'hot_topic_little_b',
    key: 'hotTopicSmallBanner',
    mappingName: 'HotTopicSmallBanner',
  },
  {
    description: '關鍵字',
    code: 'keywords',
    key: 'keywords',
    mappingName: 'Keywords',
  },
  {
    description: '重點推薦',
    code: 'emphasize_recommend',
    key: 'emphasize',
    mappingName: 'Emphasize',
  },
  {
    description: '熱門暢銷榜',
    code: 'online_leaderboard',
    key: 'hotTopicChart',
    mappingName: 'HotTopicChart',
  },
  {
    description: '主題企劃',
    code: 'main_topic_project',
    key: 'mainTopicProject',
    mappingName: 'MainTopicProject',
  },
  {
    description: '分類推薦',
    code: 'recommend_by_category',
    key: 'categoryRecommend',
    mappingName: 'CategoryRecommend',
  },
  {
    description: '精選活動',
    code: 'focus_event',
    key: 'focusEvent',
    mappingName: 'FocusEvent',
  },
  {
    description: '策展小b',
    code: 'exhibition_little_b',
    key: 'exhibitionSmallBanner',
    mappingName: 'ExhibitionSmallBanner',
  },
  {
    description: '橫幅',
    code: 'horizontal_paint',
    key: 'horizontalPaint',
    mappingName: 'HorizontalPaint',
  },
];

/**
 * 分類頁廣告「類型」
 * {object} categoryADType { code: code } 組合
 * @sample bigBanner: 'big_b'
 * =============================================
 * */
export const categoryADTypeConstant = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.code], [x.code]);
    }, categoryADType),
  );
})();

/**
 * 分類頁廣告「類型」
 * {object} categoryADType { key: code } 組合
 * @sample bigBanner: 'big_b'
 * =============================================
 * @property bigBanner 大Banner
 * @property dailyRecommend 今日最推
 * @property newProducts 新品推薦
 * @property editorRecommend 編輯推薦
 * @property mainPromo 主打選品
 * @property hotTopic 熱門話題
 * @property hotTopicSmallBanner 熱門話題小 banner
 * @property keywords 關鍵字
 * @property emphasize 重點推薦
 * @property mainTopicProject 主題企劃
 * @property categoryRecommend 分類推薦
 * @property focusEvent 精選活動
 * @property exhibitionSmallBanner 策展小b
 * */
export const categoryADTypeEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.key], [x.code]);
    }, categoryADType),
  );
})();

/**
 * 分類頁廣告 值對應的 key
 *   用來對應 method name
 * {object} categoryADType { code: key } 組合
 * @sample 'big_b': bigBanner
 * */
export const categoryADKeyEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.code], [x.key]);
    }, categoryADType),
  );
})();

/**
 * 分類頁廣告「Mapping」
 * {object} categoryADType { code: mappingName } 組合
 * @sample 'big_b': BigBanner
 * =============================================
 * */
export const categoryADMappingEnum = (() => {
  return mergeAll(
    map((x) => {
      return zipObj([x.code], [x.mappingName]);
    }, categoryADType),
  );
})();

export const hotTopicChartEnum = {
  online_leaderboard_by_day: '日排行',
  online_leaderboard_by_week: '週排行',
  online_leaderboard_by_month: '月排行',
};

import { map } from 'ramda';
import { adTypeEnum } from '@/constant/ad/ad-type';
import { emptyReplace } from '@/helper/data-process';
import { extraFieldEnum, productFormatterMaker } from '@/helper/product-formatter';

export const homePageBasicFieldFormatter = (ad) => ({
  page: emptyReplace(ad.page, adTypeEnum.home),
  type: emptyReplace(ad.banner_type, ''),
  blank: emptyReplace(ad.blank, false),
});

export const formatThreadBannerSmall = (ad) => ({
  blank: emptyReplace(ad.blank, false),
  items: map(
    (item) => ({
      alt: emptyReplace(item.alt, ''),
      link: emptyReplace(item.link, '#'),
      position: emptyReplace(item.sort, 99),
      image: emptyReplace(item.image, ''),
      title: emptyReplace(item.title_1, ''),
      subtitle: emptyReplace(item.title_2, ''),
    }),
    emptyReplace(ad.items, []),
  ),
});

export const formatThreadProducts = (ad) => {
  const productFormatter = productFormatterMaker({ extraFields: [extraFieldEnum.preorder] });
  return {
    blank: emptyReplace(ad.blank, false),
    products: map(productFormatter, emptyReplace(ad.products, [])),
  };
};

export const formatThreadKeywords = (ad) => ({
  blank: emptyReplace(ad.blank, false),
  keywords: map(
    (keyword) => ({
      link: emptyReplace(keyword.link, '#'),
      text: emptyReplace(keyword.text, ''),
    }),
    emptyReplace(Object.values(ad.keywords), []),
  ),
});

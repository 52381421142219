import { tap, difference } from 'ramda';
import { isEmptyValue } from '@/helper/data-process';

export const pipePrint = tap((res) => {
  console.log(res);
  return res;
});

const wrapper = (message, closure) => {
  if (!isEmptyValue(message)) console.group(`======= ${message} ========`);
  closure();
  if (!isEmptyValue(message)) console.groupEnd(`======= end of ${message} ========`);
};
export const consoleData = (data, message = '') => {
  wrapper(message, () => console.log(data));
};

export const printObject = (data, message = '') => {
  wrapper(message, () => console.log(JSON.stringify(data)));
};

/** @type {string} blackLogStyle log樣式 (黑色) */
export const blackLogStyle = 'background: #2E495E;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;';

export const compareArray = (less, more) => {
  return difference(more, less);
};

import * as Sentry from '@sentry/vue';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';

export default ({ app }) => {
  if (import.meta.env.SENTRY_DSN && import.meta.env.CI_COMMIT_SHA) {
    Sentry.init({
      app,
      ignoreErrors: [/Unexpected token '='\. Expected an opening '\(' before a method's parameter list\./i, /Unexpected private name #i\. Cannot parse class method with private name\./i],
      denyUrls: [
        /img\.scupio\.com/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
      dsn: import.meta.env.SENTRY_DSN,
      integrations: [new HttpClientIntegration({ failedRequestStatusCodes: [500] }), new Sentry.BrowserTracing()],
      release: import.meta.env.CI_COMMIT_SHA,
      tracesSampleRate: 1.0,
    });
  }
};

import { ref } from 'vue';
import { imageIsExist } from '@/helper/image';

export default function () {
  const isImageError = ref(false);
  const checkImageFile = async (image) => {
    const isExist = await imageIsExist(image);
    isImageError.value = !isExist;
  };
  return {
    isImageError,
    checkImageFile,
  };
}

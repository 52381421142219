/**
 * Local Storage 購物車
 * @sample Local storage 內容
    {
      "date": 1589336024697,
      "items": [
        {
          "id": "1001144562741745",
          "qty": 4
        }
      ]
    }
 */
import { findIndex, isEmpty, prepend, map, includes, length } from 'ramda';
import { LocalStorageKey } from '@/constant/local-storage';
import { CartItemOverException } from '@/exception/cart-item-over-exception';
import { cartItemsMax } from '@/constant/cart/add-cart';

export const emptyCart = {
  date: null,
  items: [],
};
export const isSupportLocal = () => !isEmpty(Storage);
export const getLocalCart = () => JSON.parse(localStorage.getItem(LocalStorageKey.cart)) || emptyCart;
export const getHasLocalCartMerged = () => JSON.parse(localStorage.getItem(LocalStorageKey.hasCartMerged)) || false;
export const setHasLocalCartMerged = (hasMerged) => (isSupportLocal() ? localStorage.setItem(LocalStorageKey.hasCartMerged, hasMerged) : null);
const getCurrentTime = () => new Date().getTime();
const isFalsy = (value) => !value;

/**
 * 單品在車內的總數
 */
export const getCartItemQuantity = (id, isAdditionalItem) => {
  // 不支援 local storage
  if (!isSupportLocal()) return 0;

  // 取得本地 local cart
  const cart = getLocalCart();

  // 找出已在車內的同款加車項目
  const result = cart.items.filter((x) => x.id === id && isFalsy(x.isAdditionalItem) === isFalsy(isAdditionalItem));

  // 沒找到就回傳0
  if (result.length === 0) return 0;

  // 有找到：回傳總數
  return result[0].qty;
};

/**
 * 單商品數量合併
 * @param id
 * @param quantity
 * @param isAdditionalItem
 * @return {*}
 */
export const mergeItem = (id, quantity, isAdditionalItem = false) => {
  // 不支援 local storage 不需要 merged item
  if (!isSupportLocal()) return quantity;

  // 取得本地 local cart
  const cart = getLocalCart();

  // 找出已在車內的同款加車項目
  const result = cart.items.filter((x) => x.id === id && isFalsy(x.isAdditionalItem) === isFalsy(isAdditionalItem));

  // 沒找到就回傳本次加車數字
  if (result.length === 0) return quantity;

  // 有找到：回傳本次＋過去的加車總數
  return result[0].qty + quantity;
};

/**
 * 檢查購物車品項上限
 * @param id
 * @param crossSellIds
 * @param max
 */
const checkItemsLength = (id, crossSellIds = [], max = cartItemsMax) => {
  // 不支援 local storage 不需要 check
  if (!isSupportLocal()) return;

  // 取得本地 local cart
  const cart = getLocalCart();
  const ids = map((x) => x.id)(cart.items); // 主商品 guid
  const crossSellsProductGuids = cart.items.flatMap((item) => item.crossSellItems.map((crossSellItem) => crossSellItem.product_guid)); // 商品加購品 guid
  const cartUniqueGuids = Array.from(new Set(ids.concat(crossSellsProductGuids))); // 篩選主商品 + 商品加購品不重覆 guid

  const addCartGuids = [...crossSellIds, id]; // 這次加入購物車的主商品 guid + 商品加購品 guid

  // 比對現有購物車及預加入購物車的商品種類沒有重覆的有幾種
  const checkNotDuplicateItemsLength = addCartGuids.reduce((acc, id) => (includes(id, cartUniqueGuids) ? acc : acc + 1), 0);

  // 現有購物車內不重覆的商品數量 + 預加入的不重覆商品數量
  const total = length(cartUniqueGuids) + checkNotDuplicateItemsLength;

  if (total > max) throw new CartItemOverException(max);
};

const updateItems = (index, qty, crossSells) => (cart) => {
  cart.items[index].qty = qty;
  cart.items[index].crossSellItems = crossSells;
  return cart;
};

const addItem = (item, cart) => {
  cart.items = prepend(item, cart.items);
  return cart;
};

const findItemIndex = ({ itemList, item }) => {
  return findIndex((currentItem) => {
    if (currentItem.id !== item.id) return false;
    return isFalsy(item.isAdditionalItem) === isFalsy(currentItem.isAdditionalItem);
  }, itemList);
};

/**
 * 寫入 local storage 購物車項目
 * @param item [{id, qty}] 商品物件
 */
export const setLocal = (item) => {
  /** get current local storage * */
  let cart = getLocalCart();

  /** update local storage * */
  // 更新時間
  cart.date = getCurrentTime();

  // 更新 cart item
  const index = findItemIndex({ itemList: cart.items, item });
  cart = index === -1 ? addItem(item, cart) : updateItems(index, item.qty, item.crossSellItems)(cart);

  localStorage.setItem(LocalStorageKey.cart, JSON.stringify(cart));
  return cart;
};

/**
 * 「覆蓋」 local storage 購物車項目
 * @param items [{id, qty}] 商品物件陣列
 */
export const overWriteLocalItems = (items) => {
  /** get current local storage * */
  const cart = getLocalCart();

  /** update local storage * */
  // 更新時間
  cart.date = getCurrentTime();
  cart.items = items;

  localStorage.setItem(LocalStorageKey.cart, JSON.stringify(cart));
  return cart;
};

/**
 * 移除 local storage 購物車項目
 * @param productId
 * @return {object|undefined} cart object
 */
export const removeLocalCartItem = (productId, isAdditionalItem = false) => {
  // fail
  if (isEmpty(productId)) return;

  // get local cart
  const localCart = getLocalCart();

  // reject product
  localCart.items = localCart.items.filter((x) => !(x.id === productId && isFalsy(x.isAdditionalItem) === isFalsy(isAdditionalItem)));

  // set local
  localStorage.setItem(LocalStorageKey.cart, JSON.stringify(localCart));

  // eslint-disable-next-line consistent-return
  return localCart;
};

/**
 * addToLocalCart
 * @param productId
 * @param quantity
 * @param isAdditionalItem
 * @param crossSellItems
 */
export const addToLocalCart = (productId, quantity, isAdditionalItem = false, crossSellItems) => {
  // 取得 item 數量
  const qty = mergeItem(productId, quantity, isAdditionalItem);
  const crossSellIds = map((x) => x.product_guid)(crossSellItems);

  checkItemsLength(productId, crossSellIds);

  // 寫入 local cart
  const cart = setLocal({
    id: productId,
    qty,
    isAdditionalItem,
    crossSellItems,
  });

  return cart;
};

export const clearLocalCart = () => {
  const cart = { date: 0, items: [] };
  localStorage.setItem(LocalStorageKey.cart, JSON.stringify(cart));
  return cart;
};

function getEventQueue() {
  // eslint-disable-next-line no-underscore-dangle
  window._bwq = window._bwq || [];
  // eslint-disable-next-line no-underscore-dangle
  return window._bwq;
}

function formatCategories(categories) {
  if (!categories) return [];
  return categories.map((x) => `${x}`);
}

/**
 * 從搜尋結果頁點擊出去的商品頁
 * @param {string} itemId 商品編號
 * @param {string[]} categories 商品類別 -- 非必要欄位
 * @param {string} searchString 使用者搜尋字串
 * @param {number} pageIndex 搜尋結果頁次
 */
function sendSearchPageLinkToProduct({ itemId, categories, searchString, pageIndex }) {
  getEventQueue().push([
    'trackSingle',
    '202-12JGC46A04CFYEG',
    'ViewContent',
    {
      contents: [
        {
          item_id: itemId,
          categories: formatCategories(categories),
        },
      ],
      search_string: searchString,
      page_index: pageIndex,
    },
  ]);
}

/**
 * 使用者進行搜尋行為
 * @param {string} searchString 使用者搜尋字串
 */
function sendSearch({ searchString }) {
  getEventQueue().push([
    'trackSingle',
    '202-12JGC46A04CFYEG',
    'Search',
    {
      search_string: searchString,
    },
  ]);
}

/**
 * 傳送使用者識別碼、登入狀態
 * @param userId {string}
 * @param isLogin {boolean}
 */
function sendInitUserInfo({ userId = '', isLogin = false }) {
  if (userId) {
    getEventQueue().push(['init', '202-12JGC46A04CFYEG', { ulid: userId }]);
    return;
  }
  getEventQueue().push(['init', '202-12JGC46A04CFYEG', { is_login: isLogin, ulid: '' }]);
}

/**
 * 一般商品頁
 * @param itemId {string} 商品編號
 * @param categories {string[]} 商品類別 -- 非必要欄位
 */
function sendProductPageView({ itemId, categories }) {
  getEventQueue().push([
    'trackSingle',
    '202-12JGC46A04CFYEG',
    'ViewContent',
    {
      contents: [
        {
          item_id: itemId,
          categories: formatCategories(categories),
        },
      ],
    },
  ]);
}

/**
 * 使用者將商品加入購物車
 * @param {item[]} contents items
 * @param {string} item.item_id 商品編號
 * @param {string[]} item.categories 商品類別 -- 非必要欄位
 * @param {number} item.quantity 商品數量
 * @param {number} item.price 商品單價
 * Note：商品沒有categories的頁面：策展頁、會員中心-收藏商品、會員中心-貨到通知
 */
function sendAddToCard({ contents }) {
  getEventQueue().push([
    'trackSingle',
    '202-12JGC46A04CFYEG',
    'AddToCart',
    {
      currency: 'TWD',
      contents,
    },
  ]);
}

/**
 * 使用者將商品加入收藏
 * @param {item[]} contents items
 * @param {string} item.item_id 商品編號
 * @param {string[]} item.categories 商品類別 -- 非必要欄位
 * @param {number} item.quantity 商品數量
 * @param {number} item.price 商品單價
 * Note：商品沒有categories的頁面：策展頁、會員中心-收藏商品、會員中心-貨到通知
 */
function sendAddToWishlist({ contents }) {
  getEventQueue().push([
    'trackSingle',
    '202-12JGC46A04CFYEG',
    'AddToWishlist',
    {
      currency: 'TWD',
      contents,
    },
  ]);
}

/**
 * 使用者瀏覽購物車頁
 */
function sendCartPageView() {
  getEventQueue().push(['trackCustom', '202-12JGC46A04CFYEG', 'CartPage']);
}

/**
 * 使用者進入結帳流程
 * @param {item[]} contents items
 * @param {string} item.item_id 商品編號
 * @param {string[]} item.categories 商品類別 -- 非必要欄位
 * @param {number} item.quantity 商品數量
 * @param {number} item.price 商品單價
 */
function sendInitiateCheckout({ contents }) {
  getEventQueue().push([
    'trackSingle',
    '202-12JGC46A04CFYEG',
    'InitiateCheckout',
    {
      currency: 'TWD',
      contents,
    },
  ]);
}

/**
 * 使用者完成購買行為
 * @param {number} value 總結帳金額
 * @param {string} transactionId 訂單編號
 * @param {item[]} contents items
 * @param {string} item.item_id 商品編號
 * @param {string[]} item.categories 商品類別 -- 非必要欄位
 * @param {number} item.quantity 商品數量
 * @param {number} item.price 商品單價
 */
function sendPurchase({ value, transactionId, contents }) {
  // console.group(`sendPurchase`);
  // console.log(['trackSingle', '202-12JGC46A04CFYEG', 'Purchase', { currency: 'TWD', value, transaction_id: transactionId, contents }]);
  // console.groupEnd();

  getEventQueue().push([
    'trackSingle',
    '202-12JGC46A04CFYEG',
    'Purchase',
    {
      currency: 'TWD',
      value,
      transaction_id: transactionId,
      contents,
    },
  ]);
}

/**
 * 使用者完成會員註冊
 */
function sendCompleteRegistration() {
  getEventQueue().push(['trackSingle', '202-12JGC46A04CFYEG', 'CompleteRegistration']);
}

export const bridgewellTrack = {
  sendSearchPageLinkToProduct,
  sendSearch,
  sendInitUserInfo,
  sendProductPageView,
  sendAddToCard,
  sendAddToWishlist,
  sendCartPageView,
  sendInitiateCheckout,
  sendPurchase,
  sendCompleteRegistration,
};

<template>
  <div class="banner-product">
    <text-in-brackets v-if="!$isEmpty(product) && showHat" class="hat" :text="product.hat" :is-single-line="isHatOneLine"></text-in-brackets>
    <div v-if="$isEmpty(product)" class="deep-product-card ec-card tw-pl-0 tw-pr-4 tw-pb-4 e-banner-product" :class="{ 'card-block': cardBlock, 'card-inline': cardInline }">
      <div class="product-link">
        <div class="product-image-box">
          <div class="e-square">
            <div class="e-aspect-ratio-inner e-skeleton-image"></div>
          </div>
        </div>
        <div class="text">
          <div v-if="showGoldenTitle" class="title"></div>
          <hr v-if="showDivider" />
          <div class="desc"></div>
        </div>
      </div>
    </div>
    <div v-else class="deep-product-card ec-card tw-pl-0 tw-pr-4 tw-pb-4 e-banner-product" :class="{ 'card-block': cardBlock, 'card-inline': cardInline, 'image-full-size': isImageFullSize }">
      <router-link-usage :id="productGtmId" :link="product.url" :title="product.title || product.name">
        <!--  商品圖片使用背景圖（2020.04版本）-->
        <div v-if="!useProductImage" class="imgbox" :class="{ boxsize: hasImageBoxSize }" :title="product.title || product.name" :alt="product.title || product.name" :style="imagePath(product)">
          <div v-if="showPreOrder && product.preorder" class="label">預購</div>
        </div>
        <!--  凱西後來寫的叉燒包版本（2020.08版本 -->
        <div v-if="useProductImage" class="product-image-box" :style="imageBoxStyle">
          <product-swiper-image v-if="isSwiperLazy" :src="product.image" :is-adult="product.isAdult"></product-swiper-image>
          <product-lazy-image v-else :src="product.image" :is-adult="product.isAdult"></product-lazy-image>
          <div v-if="showPreOrder && product.preorder" class="label">預購</div>
          <price-label v-if="isUsingPriceLabel" :guid="product.id" :class="{ isTag2: showPreOrder && product.preorder }"></price-label>
        </div>
        <div class="text">
          <slot name="custom-part"></slot>
          <div v-if="showGoldenTitle" class="title ellipsis" :title="product.title || product.name">{{ product.title || product.name }}</div>
          <hr v-if="showDivider" />
          <div v-if="showRank" class="rank tw-mt-4">
            <span>TOP</span>
            <span>{{ rank }}</span>
          </div>
          <div class="desc" :title="product.name">{{ product.name }}</div>
          <div v-if="showAuthor" class="author" :title="product.author" :class="{ 'is-one-line': isAuthorOneLine }">{{ product.author }}</div>
          <div v-if="showDetail" class="detail" :title="product.detail">{{ product.detail }}</div>
          <percentage-discount-price
            v-if="showPrice"
            class="price-block"
            :class="{ 'text-wrap': isPriceWrap }"
            :guid="product.id"
            :show-price="showPrice"
            :allow-zero="false"
            :type="priceStyle"
            :hide-discount-label="isUsingPriceLabel"
          ></percentage-discount-price>
        </div>
      </router-link-usage>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useProductItemWidth } from '@/composables/product-item/product-item-width';
import PercentageDiscountPrice from '@/components/utility/product/part/percentage-discount-price.vue';
import TextInBrackets from '@/components/utility/product/part/text-in-brackets.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import ALinkUsage from '@/components/utility/a-link-usage.vue';
import { imagePath } from '@/helper/ad/ad-helper';
import { bannerPriceType } from '@/constant/product/banner-price';
import PriceLabel from '@/components/utility/product/price-label.vue';
import ProductLazyImage from '@/components/utility/product/image/product-lazy-image.vue';
import ProductSwiperImage from '@/components/utility/product/image/product-swiper-image.vue';

const props = {
  // 刷新價格、component 元件的 flag (用於:key)
  refreshFlag: {
    type: Number,
    default: 0,
  },
  // 商品資料主體
  product: {
    type: Object,
    default: () => [],
  },
  // 使用商品圖片: 叉燒包、18禁
  useProductImage: {
    type: Boolean,
    default: false,
  },
  showPreOrder: {
    type: Boolean,
    default: false,
  },
  // 是否顯示排名（用於暢銷排行榜）
  showRank: {
    type: Boolean,
    default: false,
  },
  showGoldenTitle: {
    type: Boolean,
    default: false,
  },
  // 是否顯示 title（分類頁 slider）
  showHat: {
    type: Boolean,
    default: false,
  },
  showAuthor: {
    type: Boolean,
    default: false,
  },
  showDetail: {
    type: Boolean,
    default: false,
  },
  showDivider: {
    type: Boolean,
    default: false,
  },
  showPrice: {
    type: Boolean,
    default: true,
  },
  hasImageBoxSize: {
    type: Boolean,
    default: true,
  },
  cardBlock: {
    type: Boolean,
    default: true,
  },
  cardInline: {
    type: Boolean,
    default: false,
  },
  rank: {
    type: Number,
    default: 1,
  },
  productGtmId: {
    type: String,
    default: '',
  },
  isAuthorOneLine: {
    type: Boolean,
    default: false,
  },
  isHatOneLine: {
    type: Boolean,
    default: false,
  },
  isPriceWrap: {
    type: Boolean,
    default: false,
  },
  isLazy: {
    type: Boolean,
    default: true,
  },
  isSwiperLazy: {
    type: Boolean,
    default: false,
  },
  isImageSquare: {
    type: Boolean,
    default: true,
  },
  isImageFullSize: {
    type: Boolean,
    default: false,
  },
  isSkeletonImage: {
    type: Boolean,
    default: false,
  },
  isUsingPriceLabel: {
    type: Boolean,
    default: false,
  },
  //
  showGrayCover: {
    type: Boolean,
    default: true,
  },
  // 價格顯示樣式
  priceStyle: {
    type: String,
    default: bannerPriceType.compare,
  },
};

const setup = (props) => {
  const { productSize } = useProductItemWidth({ productWidth: 0, freeWidth: false });

  const imageBoxStyle = computed(() => {
    if (props.isImageFullSize || props.isImageSquare) return {};
    return { 'max-width': `${productSize.value}px` };
  });

  return {
    // methods
    imagePath,
    // computed
    imageBoxStyle,
  };
};

const components = {
  PercentageDiscountPrice,
  TextInBrackets,
  RouterLinkUsage,
  ALinkUsage,
  PriceLabel,
  ProductLazyImage,
  ProductSwiperImage,
};

export default {
  name: 'BannerProduct',
  components,
  props,
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/utility/banner-product.scss';
.e-banner-product {
  margin: 0 auto;
}
@include mediaMin($grid-breakpoints-lg) {
  .theme-product > .e-banner-product {
    @include pxToRem(width, 87.6);
  }
}
</style>

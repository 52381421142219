import { discountTypeEnum } from '@/constant/product';

/**
 * 去`0`
 *  - 舉例：`80`折 顯示為 `8`折
 * @param range
 * @return {string}
 */
export const formatDiscountRange = (range) => (range === 100 ? '' : `${parseInt(range, 10)}`.replace(/0/, ''));

/**
 * 折數
 * @param discount
 * @return {string}
 */
export const formatDiscountLevel = (discount) => {
  if (discount.type === discountTypeEnum.percentage) {
    return discount.range === 100 ? '' : `${formatDiscountRange(discount.range)} 折`;
  }
  if (discount.type === discountTypeEnum.amount) {
    return `省 ${parseInt(discount.range, 10)}`;
  }
  return '';
};

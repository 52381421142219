import * as yup from 'yup';
import '@/helper/yup/custom-methods';

/**
 * 廣告版位基本欄位
 * @type {{blank: *, banner_type: *, page: *}}
 */
export const homeAdBaseSchema = {
  page: yup.string().required(),
  banner_type: yup.string().required(),
  blank: yup.boolean().required(),
};

/**
 * 商品基本格式
 */
export const baseProductSchema = yup.object().shape({
  product_guid: yup.string().required(),
  eslite_sn: yup.string().required(),
  name: yup.string().required(),
  image: yup.string().strictString(),
  preorder: yup.boolean().required(),
  short_description: yup.string().strictString(),
  author: yup.string().strictString(),
  restricted: yup.boolean().required(),
  product_link: yup.string().strictString(), // if link or 自組 product link ?
});

/**
 * Banner Items 基本格式
 */
export const baseAdItemsSchema = yup.object().shape({
  id: yup.number().required(),
  alt: yup.string().required(),
  image: yup.string().required(),
  link: yup.string().required(),
  sort: yup.number().required(),
});

export const baseAdItemsWithTitleSchema = yup.object().shape({
  id: yup.number().required(),
  title_1: yup.string().required(),
  title_2: yup.string().required(),
  alt: yup.string().required(),
  image: yup.string().required(),
  link: yup.string().required(),
  sort: yup.number().required(),
});

/**
 * Banner 基本格式
 * （基本欄位 + banner items）
 */
export const baseBannerSchema = yup.object().shape({
  // 基本 required 欄位: page, banner type, blank
  ...homeAdBaseSchema,
  // 套入 banner items
  items: yup.array().of(baseAdItemsSchema),
});

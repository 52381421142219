/** @const {string[]} cartPath 全部的購物車路徑 */
export const cartPath = ['/cart/step1', '/cart/step2', '/cart/step3'];

/** @const {string[]} memberPath 全部會員中心內需要登入的路徑 */
export const memberPath = [
  '/member/coupons',
  '/member/account',
  '/member/points',
  '/member/orders',
  '/member/notify',
  '/member/profile/modify-password',
  '/member/profile/modify-profile',
  '/member/profile',
  '/member/wish',
  '/member/affiliateprogram',
];
export const memberCenterPath = ['/member'];

/** @const {string[]} usePrivacyUserPage 需要隱碼個資的頁面名稱 (route-name) */
export const usePrivacyUserPage = [
  'member',                            // `/member` 會員中心
  'member-profile',                    // `/member/profile` 會員資料修改
  'member-profile-modify-profile',     // `/member/profile/modify-profile` 會員資料修改
  'member-customer-customer-service',  // `/member/customer/customer-service` 聯絡客服表單
  'member-affiliateprogram',           // `/member/affiliateprogram` AP策略聯盟
  'member-verify-phone',               // `/member/verify/phone` 手機驗證
  'forgot',                            // `/forgot` 忘記密碼
  'login',                             // `/login` 登入
  // 'cart-step2',                        // `/cart/step2` 購物車2
];

/** @const {string[]} default need login path */
export default [...memberPath];

<template>
  <div v-if="price" class="percentage-discount-price-wrapper">
    <static-percentage-price v-if="isSimple" :price="price" :discount="discountPriceOnly" :allow-zero="allowZero"></static-percentage-price>
    <simple-static-percentage-price v-if="isSimpleWithoutNT" :price="price" :discount="discountPriceOnly" :allow-zero="allowZero"></simple-static-percentage-price>
    <show-final-price v-if="isShowFinalPrice" :pre-price="prePrice" :price="price" :discount="discountPriceOnly" :allow-zero="allowZero"></show-final-price>
    <HotBuyPercentagePrice v-if="isHotBuy" :pre-price="prePrice" :price="price" :discount="discountPriceOnly" :allow-zero="allowZero" :hide-discount-label="hideDiscountLabel"></HotBuyPercentagePrice>
    <compare-percentage-price
      v-if="isCompare"
      :pre-price="prePrice"
      :price="price"
      :discount="discountPriceOnly"
      :allow-zero="allowZero"
      :hide-discount-label="hideDiscountLabel"
      :hide-pre-price="hidePrePrice"
    ></compare-percentage-price>
  </div>
  <div v-else class="percentage-discount-price-wrapper"></div>
</template>

<script>
import { toRefs, computed } from 'vue';
import StaticPercentagePrice from '@/components/utility/product/part/static-percentage-price.vue';
import SimpleStaticPercentagePrice from '@/components/utility/product/part/simple-static-percentage-price.vue';
import HotBuyPercentagePrice from '@/components/utility/product/part/hot-buy-percentage-price.vue';
import ComparePercentagePrice from '@/components/utility/product/part/compare-percentage-price.vue';
import { bannerPriceType } from '@/constant/product/banner-price';
import productPrice from '@/composables/product/product-price';
import ShowFinalPrice from '@/components/utility/product/part/show-final-price.vue';

const props = {
  showPrice: {
    type: Boolean,
    default: true,
  },
  guid: {
    type: String,
    default: '',
  },
  allowZero: {
    type: Boolean,
    default: false,
  },
  hotBuy: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: bannerPriceType.simple,
  },
  hideDiscountLabel: {
    type: Boolean,
    default: false,
  },
  hidePrePrice: {
    type: Boolean,
    default: false,
  },
  useQueue: {
    type: Boolean,
    default: true,
  },
};

const setup = (props) => {
  const { showPrice, guid, type, useQueue } = toRefs(props);
  const isShow = computed(() => showPrice.value);
  const isHotBuy = computed(() => type.value === bannerPriceType.hotBuy);
  const isCompare = computed(() => type.value === bannerPriceType.compare);
  const isSimpleWithoutNT = computed(() => type.value === bannerPriceType.simpleWithoutNT);
  const isShowFinalPrice = computed(() => type.value === bannerPriceType.showFinalPrice);
  const isSimple = computed(() => {
    if (!showPrice.value) return false;
    return !type.value || type.value === bannerPriceType.simple;
  });
  const { prePrice, price, discountPriceOnly, targetFetchPrice } = productPrice({ guid: guid.value, useQueue: useQueue.value });
  const initPrice = () => {
    if (!showPrice.value) return;
    if (!guid.value) return;
    targetFetchPrice({
      productIds: [guid.value],
    });
  };

  initPrice();

  return {
    isShow,
    isHotBuy,
    isCompare,
    isSimpleWithoutNT,
    isShowFinalPrice,
    isSimple,

    /**
     * Composable
     */
    prePrice,
    price,
    discountPriceOnly,
  };
};

export default {
  name: 'PercentageDiscountPrice',
  components: { ShowFinalPrice, StaticPercentagePrice, SimpleStaticPercentagePrice, HotBuyPercentagePrice, ComparePercentagePrice },
  props,
  setup,
};
</script>

<style lang="scss" scoped>
.percentage-discount-price-wrapper {
  min-height: 30px;
}
</style>

<template>
  <div class="product-image e-square" :title="alt" @click="$emit('click')" @mouseover="$emit('mouseover')">
    <div v-if="!restricted" class="item-image-wrap e-aspect-ratio-inner" :title="alt">
      <slot name="cover">
        <div class="gray-cover"></div>
      </slot>
      <template v-if="!$isEmpty(src)">
        <swiper-webp-image :src="src" :alt="alt"></swiper-webp-image>
      </template>
      <img v-else src="@/static/images/product-default@3x.webp" alt="" />
    </div>
    <div v-else class="item-image-wrap e-aspect-ratio-inner bg-l-gray-f4">
      <image-wait-loaded :is-skeleton-image="isSkeletonImage">
        <img src="@/static/images/adult-only.webp" alt="" />
      </image-wait-loaded>
    </div>
  </div>
</template>

<script>
import { toRef } from 'vue';
import ImageWaitLoaded from '@/components/utility/image/image-wait-loaded.vue';
import SwiperWebpImage from '@/components/utility/image/swiper-webp-image.vue';
import { useProductImageRestrict } from '@/composables/product/product-image-restrict';

const props = {
  alt: {
    type: String,
    default: '',
  },
  src: {
    type: String,
    default: '',
  },
  isAdult: {
    type: Boolean,
    default: false,
  },
  disableLimit: {
    type: Boolean,
    default: false,
  },
  isSkeletonImage: {
    type: Boolean,
    default: false,
  },
};

const setup = (props) => {
  const disableLimit = toRef(() => props.disableLimit);
  const isAdult = toRef(() => props.isAdult);
  const { restricted } = useProductImageRestrict({ disableLimit, isAdult });

  return {
    restricted,
  };
};

export default {
  name: 'ProductSwiperImage',
  components: { ImageWaitLoaded, SwiperWebpImage },
  props,
  setup,
};
</script>

<style lang="scss" scoped>
.product-image,
.item-image-wrap {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.product-image {
  position: relative;
}
.gray-cover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.03);
  pointer-events: none;
}
.item-image-wrap {
  :deep(img) {
    width: auto; // 避免 safari, firefox 圖片被撐開跑版
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
.swiper-lazy-preloader {
  background-size: contain;
  background-image: url('@/static/images/loading_large.gif');
  background-position: center;
  background-repeat: no-repeat;
  width: inherit;
  height: inherit;
  top: 0;
  left: 0;
  margin: 0;
  animation: none;
  border: none;
}
</style>

import { values, includes, forEach } from 'ramda';
import { dtoExpose } from '@/helper/dto';

export const productDtoTypeEnum = {
  simple: 'simple',
  standard: 'standard',
  search: 'search',
};

/**
 * 基本欄位 (data 面)
 * todo: export for test ，之後應該要把 export 拿掉
 */
export const simpleBaseColumns = [
  'id',
  'name',
  'author',
  'originAuthor',
  'compiler',
  'translator',
  'painter',
  'brandName',
  'discount',
  'price',
  'image',
  'publisher',
  'publishDate',
  'description',
  'status',
  'isAdult',
  'limit',
  'accountLimit',
  'isBook',
  'isGift',
  'isTransfer',
];

export const standardBaseColumns = [
  ...simpleBaseColumns,
  'images', // 多張圖
  'subTitle',
  'originalName',
  'supplier',
  'salePrice', // todo: 註記-可能和 simple 重複但不同名
  'manufacturerDate', // todo: 註記-可能和 simple 重複但不同名
  'promoWord',
  'discountLevel', // todo: 註記-可能和 simple 重複但不同名
  'tags',
  'restricted', // todo: 註記-可能和 simple 重複但不同名
  'type',
  'stock',
  'leastBuyCountType',
  'lowerLimit',
  'isNormal',
  'saleModeText',
  'salesＷarning',
  'activities',
  'buttonStatus', // todo: 註記-可能和 simple 重複但不同名
  'level1',
  'level2',
  'level3',
  'categories',
  'esliteSn',
  'installments',
  'hotSellingWording',
  'relations',
  'isHiddenDiscount',
  'priceDiscount',
  'mallProducts',
  'hasCrossSell',
  'feedbackDescription',
];
const searchBaseColumns = [...simpleBaseColumns];
const baseColumns = {
  [productDtoTypeEnum.simple]: simpleBaseColumns,
  [productDtoTypeEnum.standard]: standardBaseColumns,
  [productDtoTypeEnum.search]: searchBaseColumns,
};

const checkDtoType = (type) => {
  if (!type) throw Error('[product dto] options missing `type`');
  if (!includes(type, values(productDtoTypeEnum))) throw Error('[options] unknown dto `type`');
};

const checkFormatter = ({ formatter }) => {
  if (!formatter) throw Error('dto missing formatter');
};

export default class BaseProductDto {
  constructor({ product, hasFormatter = true } = {}) {
    if (hasFormatter) return;
    Object.keys(product).forEach((key) => (this[key] = product[key]));
    this.hasFormatter = hasFormatter;
  }

  // eslint-disable-next-line class-methods-use-this
  validateOptions(options, type) {
    checkDtoType(type);
    if (this.hasFormatter) checkFormatter(options);
  }

  setData(formattedProduct, type, mergeColumns = []) {
    forEach(
      (x) => {
        this[x] = formattedProduct[x];
      },
      [...baseColumns[type], ...mergeColumns],
    );
  }

  /**
   * > 加入購物車依賴參數
   *    guid 商品 UUID
   *    name 商品名稱
   *    salePrice 商品價錢
   *    limit 訂單限購數量
   *    accountLimit 帳號限購數量
   *    quantity 本次加車（購買）數量
   *    crossSellsQuantityLimit 一個主商品可加購商品加購品總數
   *    isAdditionalItem 是否為加購品（step1 才會出現）
   * > GTM add_to_cart 參數
   *    name 商品名稱
   * @returns {{accountLimit: (null|*), quantity, limit, guid, isAdditionalItem: (*|boolean)}}
   */
  toAddCart() {
    return {
      guid: this.id,
      name: this.name,
      salePrice: this.salePrice,
      limit: this.limit,
      accountLimit: this.accountLimit,
      crossSellsQuantityLimit: this.crossSellsQuantityLimit,
      isAdditionalItem: this.isAdditionalItem || false,
    };
  }

  expose() {
    return dtoExpose(this);
  }
}

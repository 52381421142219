<template>
  <div :class="`${name}-modal`" data-test-id="single-button-modal">
    <transition name="modal">
      <div class="modal-mask" @click="onClick">
        <div class="modal-wrapper">
          <div class="modal-container tw-container tw-m-auto" :style="contentWidth">
            <div class="body" :class="customBodyClass" :style="contentStyle">
              <slot name="body"></slot>
            </div>
            <div class="footer">
              <slot name="footer">
                <button ref="footerBtnRef" class="tw-rounded ec-btn ec-btn-block" type="button" :style="buttonStyle" :class="buttonClass" @click="$emit('close')">{{ buttonText }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, toRefs, computed } from 'vue';

const props = {
  name: {
    type: String,
    default: 'common',
  },
  width: {
    type: Number,
    default: 600,
  },
  customBodyClass: {
    type: String,
    default: '',
  },
  customContent: {
    type: Object,
    default: () => ({}),
  },
  customButton: {
    type: Object,
    default: () => ({}),
  },
  theme: {
    type: String,
    default: 'e-mid-gold',
  },
  buttonText: {
    type: String,
    default: '好',
  },
};

const setup = (props, { emit }) => {
  const footerBtnRef = ref(null);
  const { width, customContent, customButton, theme, buttonText, errorType } = toRefs(props);

  /** computed */
  const buttonClass = computed(() => ({ [`ec-btn-${theme.value}`]: true }));
  const buttonStyle = computed(() => ({ ...customButton.value }));
  const contentWidth = computed(() => ({ width: `${width.value}px`, 'max-width': `98vw` }));
  const contentStyle = computed(() => ({ ...customContent.value }));

  const onClick = () => emit('click');

  onMounted(() => {
    footerBtnRef.value?.focus();
  });
  return {
    footerBtnRef,
    theme,
    buttonText,
    /** computed */
    buttonClass,
    buttonStyle,
    contentWidth,
    contentStyle,
    /** methods */
    onClick,
  };
};

export default {
  name: 'SingleButtonModal',
  props,
  emits: ['click', 'close'],
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/utility/modal.scss';
</style>

import { defineStore } from 'pinia';
import { isEmptyValue } from '@/helper/data-process';
import CategoryService from '@/services/CategoryService';
import { defaultCategoryNode } from '@/constant/category/sidebar';

/**
 * 建立平面化館別列表
 * @param {object} all category list object
 * @return {array} planeList 平面化館別列表
 */
export const genPlaneList = (all) => {
  const planeList = [];
  all.forEach((l1) => {
    planeList.push({ ...defaultCategoryNode, depth: l1.depth, id: l1.id, level1: l1.id, level1Description: l1.description, level1Key: l1.key, isBrand: l1.is_brand, color: l1.color });

    l1.children.forEach((l2) => {
      planeList.push({ ...defaultCategoryNode, depth: l2.depth, id: l2.id, level1: l1.id, level2: l2.id, level1Description: l1.description, level2Description: l2.description });
      l2.children.forEach((l3) =>
        planeList.push({
          depth: l3.depth,
          id: l3.id,
          level1: l1.id,
          level2: l2.id,
          level3: l3.id,
          level1Description: l1.description,
          level2Description: l2.description,
          level3Description: l3.description,
        }),
      );
    });
  });

  return planeList;
};

/* （備用先保留）濾掉key:children */
// const discardChildren = (data) => {
//   // 如果沒有children直接回傳
//   if (isEmptyValue(data?.children)) return;
//
//   const { children, ...result } = data;
//
//   return result;
// };

const state = () => ({
  // 記錄用館別層級
  currentLevel: 0,
  // 記錄用館別 id
  currentId: '',
  // 館別全資料
  categories: [],
  // 平面速查表
  planeList: [],
});

const getters = {
  // 取得全部館別資訊
  getCategories: (state) => state.categories,
  // 取得一般類別館別資訊 menu-bar
  getGeneralCategories: (state) => state.categories.filter((level1) => !level1.is_brand),
  // 取得品牌館類別
  getBrandCategory: (state) => state.categories.find((level1) => level1.is_brand),
  // 取得全部平面化館別資訊
  getPlaneList: (state) => state.planeList,
  // query 查館別資訊(無差別搜尋)
  categoriesQueryGetter: (state) => (id) => state.planeList.find((x) => x.id === id),
  /**
   * query 透過階層進行查找
   *
   * 舉例來說:
   * 找一層: queryViaDepth(1257)
   * 找兩層: queryViaDepth(1257, 1263)
   * 找三層: queryViaDepth(1257, 1263, 1264)
   */
  queryViaDepth:
    (state) =>
    (...args) => {
      const l1 = state.categories?.find((x) => x.id === args[0]);
      // 第1次觸發沒有資料回來，回傳的結果為undefined。
      if (isEmptyValue(l1)) return;

      switch (args.length) {
        case 1:
          return l1;
        case 2:
          return l1?.children?.find((x) => x.id === args[1]);
        case 3:
          return l1?.children?.find((x) => x.id === args[1])?.children?.find((x) => x.id === args[2]);
        default:
          console.error(`Sorry for wrong quantity to query: ${args.length}, expected 1 to 3`);
      }
    },
  // 取得館別層級
  getCurrentLevel: (state) => state.currentLevel,
  // 取得館別 id
  getCurrentId: (state) => state.currentId,
};

const actions = {
  fetchCategories() {
    if (this.categories.length > 0) return;
    CategoryService.getCategories()
      .then((response) => {
        this.planeList = genPlaneList(response.data);
        this.categories = response.data;
      })
      .catch(() => console.error(`can't get categories.`));
  },
  setHistory(data) {
    this.currentLevel = data.level || 0;
    this.currentId = data.id || '';
  },
};

export const useCategoriesStore = defineStore('categories', {
  state,
  getters,
  actions,
});

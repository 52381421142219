<template>
  <div class="leftbox row mx-0 px-0 ps-0">
    <div class="mobile-scroll row mx-0 px-0 product-wrapper">
      <product-with-title
        v-for="(product, index) in products"
        :key="`recommend-product-${index}`"
        class="col-3"
        :refresh-flag="refreshFlag"
        :product-gtm-id="!hasDailyRecommend ? '' : sourceID + incrementItem(index)"
        :product="product"
      ></product-with-title>
    </div>
  </div>
</template>

<script>
import { toRef, computed } from 'vue';
import { imagePath } from '@/helper/ad/ad-helper';
import ProductWithTitle from '@/components/product/item/product-with-title.vue';
import { incrementItem } from '@/helper/tracking/gtm-id';

const components = {
  ProductWithTitle,
};

const props = {
  refreshFlag: {
    type: Number,
    default: 0,
  },
  sourceID: {
    type: String,
    default: '',
  },
  isSkeletonImage: {
    type: Boolean,
    default: false,
  },
  products: {
    type: Array,
    default: () => [],
  },
};

export default {
  name: 'Recommend',
  components,
  props,
  setup(props) {
    const products = toRef(() => props.products);
    const hasDailyRecommend = computed(() => !!products.value?.length);

    return {
      hasDailyRecommend,
      imagePath,
      incrementItem,
    };
  },
};
</script>

<style lang="scss" scoped>
.mobile-scroll {
  width: 180%;
  max-width: 180%;
  flex: 0 0 180%;
  height: auto;
}

// 768-991px (desktop 窄版)
@include mediaRange($grid-breakpoints-md, $grid-breakpoints-lg) {
  .card .imgbox {
    height: 150px;
  }
}

@include mediaMin($grid-breakpoints-sm) {
  .mobile-scroll {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@include mediaMin($grid-breakpoints-lg) {
  .product-wrapper {
    justify-content: space-between;
  }
  .col-3 {
    padding: 0;
    flex: 0 0 23%;
    max-width: 23%;
  }
}

@include mediaMin($grid-breakpoints-xl) {
  .card .imgbox {
    height: 200px;
  }
}

@include mediaMax($grid-breakpoints-lg) {
  .leftbox.row {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

@include mediaMin($grid-breakpoints-lg) {
  .card {
    /*max-height: 312px;*/
    height: auto;

    .text {
      height: 34%;
      @include pxToRem(padding-top, 11);
      overflow: hidden;
    }
  }
}
</style>

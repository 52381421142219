<template>
  <div class="all-group-title">
    <div v-for="(text, index) in data" :key="`recommend-tabs-button-${index}`" class="title" data-test-id="recommend-tab-button" @click="select(index)">
      <div v-if="index === value" class="tab active" data-test-id="active">
        <div class="tab-content">
          <span>{{ text }}</span>
        </div>
        <div class="tab-arrow"></div>
      </div>
      <div v-else class="tab inactive">
        <div class="tab-content">
          <a :href="void 0">{{ text }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const props = {
  data: {
    type: Array,
    default: () => [],
  },
  value: {
    type: Number,
    default: 0,
  },
};

export default {
  name: 'RecommendTabs',
  props,
  emits: ['select'],
  setup(props, { emit }) {
    const select = (index) => {
      emit('select', index);
    };

    return {
      select,
    };
  },
};
</script>

<style lang="scss" scoped>
.all-group-title {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  .title:hover {
    cursor: pointer;
  }
  .title {
    color: var(--white);
    .tab-content {
      display: flex;
      justify-content: center;
      @include pxToRem(border-radius, 6);
      a {
        color: var(--white);
        text-decoration: none;
      }
    }
    .active > .tab-content {
      background-image: linear-gradient(to bottom, #ff7a00, #ff5c00);
    }
    .inactive > .tab-content {
      background-color: #ccc;
    }
  }
}
.tab-arrow {
  border-color: #ff5c00 transparent transparent transparent;
  border-style: solid solid solid solid;

  /* 設定邊框依上、下；左右，各為一群組，相同群組須設定為相同尺寸，調整尺寸可拼湊出任意形狀的三角形 */
  border-width: 10px 10px 15px 10px;

  /* 設定 width、height 可更好理解原理 */
  height: 0px;
  width: 0px;

  //
  position: relative;
  margin-top: -1px;
}

#virtual-shelf-layout .tab-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

// only 小版
@include mediaMax($grid-breakpoints-lg) {
}

@mixin mobileStyle($size) {
  .all-group-title {
    .title {
      @include pxToRem(margin-left, math.div(24, mobileRate($size)));
      @include pxToRem(font-size, math.div(42, mobileRate($size)));
      .tab {
        @include pxToRem(width, math.div(420, mobileRate($size)));
        @include pxToRem(max-width, math.div(420, mobileRate($size)));
      }
      .tab-content {
        //@include pxToRem(border-radius, 6);
        @include pxToRem(padding, math.div(18, mobileRate($size)), math.div(36, mobileRate($size)));
        @include pxToRem(height, math.div(96, mobileRate($size)));
      }
    }
  }
  .tab-arrow {
    $width: math.div(330, mobileRate($size));
    margin-left: calc(#{$width}px / 2 - 10px);
  }
}

@include mobileStyle($mobile-container-xs);

@include mediaIs($grid-breakpoints-sm) {
  @include mobileStyle($mobile-container-sm);
}

@include mediaIs($grid-breakpoints-md) {
  @include mobileStyle($mobile-container-md);
}

// only big
@include mediaMin($grid-breakpoints-lg) {
  .all-group-title {
    .title {
      width: 20%;
      max-width: 20%;
      @include font16;
      .tab {
        @include pxToRem(width, 157);
        max-width: 100%;
      }
      .tab-content {
        @include pxToRem(padding, 6, 12);
        @include pxToRem(height, 35);
      }
    }
  }
  .tab-arrow {
    margin-left: calc(157px / 2 - 10px);
  }
}
@include mediaIs($grid-breakpoints-lg) {
  .all-group-title {
    .title {
      width: 24%;
      max-width: 24%;
    }
  }
}
</style>

import BaseProductDto, { productDtoTypeEnum } from '@/dto/base-product-dto';

/**
 * HomeAdProductDto
 *  > 加入購物車依賴參數
 *    guid 商品 UUID
 *    limit 訂單限購數量
 *    accountLimit 帳號限購數量
 *    quantity 本次加車（購買）數量
 *    isAdditionalItem 是否為加購品（購物車用？）
 */

// 功能性欄位（不共用）
const featureColumns = ['title', 'alt', 'url'];

export default class HomeAdProductDto extends BaseProductDto {
  constructor(product, options) {
    super();
    const type = productDtoTypeEnum.simple;
    super.validateOptions(options, type);
    super.setData(options.formatter(product), type, featureColumns);
  }

  setKey(data) {
    this.key = data;
  }

  toAddCart() {
    return super.toAddCart();
  }
}

import * as yup from 'yup';
import { baseAdItemsWithTitleSchema, homeAdBaseSchema } from '@/schema/api/ad/home-page/common';

/**
 * [HomePage Ad] `brand_week` api response validate
 */
export const homePageBrandWeekSchema = yup.object().shape({
  // 基本 required 欄位: page, banner type, blank
  ...homeAdBaseSchema,
  // 套入 banner items
  items: yup.array().of(baseAdItemsWithTitleSchema),
});

import axios from 'axios';

const API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_CART_ITEMS_V2}`;

export default (token, params) =>
  axios({
    method: 'get',
    headers: { Authorization: token },
    url: API_PATH,
    params,
  });
